<template>
    <div style="display:flex;justify-content: center; align-items: center; height: 91vh;">
        <v-form name="formSetting"
                class="shadow-box"
                style="border:1px solid #e0e0e0; padding:10px; width:55%">
            <TabView scrollable
                     style="overflow: visible;"
                     :pt="{ panelContainer: { class: 'form-tab-panel' } }">
                <TabPanel :header="__('Basic data')"
                          headerClass="form-tab-header">
                    <div class="grid">
                        <FormCalendar fieldName="test_current_date"
                                      label="Test current date"
                                      size="2"
                                      :isFormDisabled="false"
                                      v-model:field="config.test_current_date" />
                        <FormInputToggleButton fieldName="is_manager_update"
                                               label="Is manager update via SAP?"
                                               size="10"
                                               v-model:field="config.is_manager_update" />

                    </div>
                </TabPanel>
            </TabView>
            <div class="grid"
                 style="margin-top: 10px">
                <Button @click="saveData"
                        :disabled="config.processing"
                        style="margin:0 5px"
                        severity="success">
                    {{ __('Save') }}
                </Button>
                <Button @click="cancelData"
                        :disabled="config.processing"
                        style="margin:0 5px"
                        severity="secondary">
                    {{ __('Cancel') }}
                </Button>
            </div>
        </v-form>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormCalendar from '@crud/FormCalendar.vue'
import FormInputToggleButton from '@crud/FormInputToggleButton.vue'
import Button from 'primevue/button';
import { useForm, router } from '@inertiajs/vue3';
import { __, convertDate } from '@pages/Helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    data: Object,
})

const config = ref(props.data)

// -----------------------------------------------------

const saveData = () => {
    useForm({ ...config.value, 'test_current_date': convertDate(config.value.test_current_date), 'is_manager_update': config.value.is_manager_update }).post(route('set-setting'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => {
            console.error(errors)
        },
        onSuccess: () => {
        },
        onFinish: () => {
        }
    })
}

// -----------------------------------------------------

const cancelData = () => {
    router.get(route('worker-index'));
}

// -----------------------------------------------------

</script>

<style lang="scss" scoped>
.shadow-box {
    -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
}
</style>
