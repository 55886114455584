<template>
    <div>
        <Transition v-if="isLoading"
                    name="spinner">
            <div id="cover-spin"></div>
        </Transition>
        <v-form>
            <div class="grid">
                <FormInputText fieldName="email"
                               label="Email"
                               validation="required"
                               :isFocus="true"
                               v-model:field="email" />
            </div>
            <div class="grid">
                <FormInputText fieldName="subject"
                               label="Subject"
                               validation="required"
                               v-model:field="subject" />
            </div>
            <div class="grid">
                <FormEditor fieldName="body"
                            label="Body"
                            validation="required"
                            :editorStyle="{ height: '120px' }"
                            v-model:field="body" />
            </div>

            <div class="grid"
                 style="margin-top:30px">
                <Button severity="success"
                        style="margin:0 8px"
                        @click="sendEmail">
                    <i class="fa-solid fa-plus"
                       style="margin-right:5px;font-size:18px"></i>
                    {{ __('Send') }}
                </Button>
                <Button severity="secondary"
                        style="margin:0 8px"
                        @click="cancel">
                    <i class="fa-solid fa-ban"
                       style="margin-right:5px;font-size:18px"></i>
                    {{ __('Cancel') }}
                </Button>
            </div>
        </v-form>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import Button from 'primevue/button';
import FormInputText from '@crud/FormInputText.vue'
import FormEditor from '@crud/FormEditor.vue'
import { validation } from '@pages/Helper/crud_utils.js'
import { __, screenMessage, getFromRoute } from '@pages/Helper/utils.js'

// -----------------------------------------------------

const isTestMailModalOpen = defineModel('isTestMailModalOpen')
const subject = ref(null)
const body = ref(null)
const email = ref(null)
const isLoading = ref(false)

// -----------------------------------------------------

const cancel = () => {
    isTestMailModalOpen.value = false
}
// -----------------------------------------------------

const sendEmail = async () => {
    const formData = {
        subject: subject.value,
        body: body.value,
        email: email.value,
    }
    if (!validation(formData)) {
        return false
    }

    isLoading.value = true
    const response = await getFromRoute('mailing-send-test', formData, 'post');
    isLoading.value = false
    screenMessage({ message: response.message, title: "Information", type: response.status == 'success' ? "info" : "error", position: "center", timeout: 5 });

    isTestMailModalOpen.value = false
}

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
