<template>
    <div>
        <Transition v-if="isLoading"
                    name="spinner">
            <div id="cover-spin"></div>
        </Transition>

        <VLayout class="rounded-md flex-column">
            <Menubar ref='menubar'
                     class="px-2 menu-horizontal-outer"
                     :model="menuItems">
                <template #start>
                    <v-col>
                        <Image :src="menuLogo" />
                    </v-col>
                </template>

                <template #item="{ item, props, hasSubmenu, root }">
                    <span v-if="Object.values(unprotectedMenuItems).includes(item?.fullPath?.toLowerCase().replace(/[_\s]/g, '-')) || hasPermission(item?.fullPath)">
                        <Link v-ripple
                              v-if="item.route"
                              :href="checkRoute(item.route, null, item.label)"
                              :id="item.id"
                              :style="item.itemstyle"
                              class="flex flex-row flex-nowrap menu-horizontal-inner"
                              :aria-hidden="false"
                              v-bind="props.action">
                        <div inert>
                            <span :class="item.icon"
                                  :style="item.iconstyle" />
                            <span class="ml-2"
                                  :class="item.labelclass"
                                  :style="item.labelstyle">{{ __(item.label) }}</span>
                            <div v-if="item.description"
                                 class="menu-horizontal-description">
                                <span v-html="item.description"></span>
                            </div>
                        </div>
                        </Link>

                        <div v-ripple
                             v-else
                             class="flex flex-row flex-nowrap menu-horizontal-inner"
                             :style="item.itemstyle"
                             v-bind="props.action">
                            <div class="align-items-start cursor-pointer w-100">
                                <span :class="item.icon"
                                      :style="item.iconstyle" />
                                <span class="ml-2"
                                      :class="item.labelclass"
                                      :style="item.labelstyle">{{ __(item.label) }}</span>
                                <i v-if="hasSubmenu"
                                   :class="['pi pi-angle-down text-primary', { 'pi-angle-down ml-2': root, 'pi-angle-right ml-2': !root }]"
                                   :style="item.submenustyle"
                                   style="float:right"></i>
                                <div v-if="item.description"
                                     class="menu-horizontal-description"
                                     :id="item.id">
                                    <span v-html="item.description"></span>
                                </div>
                            </div>
                        </div>
                    </span>
                </template>

                <template #end>
                    <div class="flex align-items-center">
                        <div class="flex menu-horizontal-left-part">
                            <div class="menu-horizontal-left-part-begin"></div>
                            <div class="menu-horizontal-left-part-middle"></div>
                            <div class="menu-horizontal-left-part-end">
                                <Button v-if="multiModuleApp"
                                        icon="pi pi-server"
                                        severity="info"
                                        text
                                        size="large"
                                        v-tooltip.top="__('Select module')"
                                        @click="selectModule" />
                            </div>
                        </div>
                        <div class="session-time-header"
                             style="margin-left:20px"></div>
                    </div>
                </template>
            </Menubar>

            <VFooter class="bg-grey-lighten-2"
                     app>
                <div class="w-full flex justify-content-between align-items-center">
                    <div class="flex flex-nowrap align-items-center">
                        <Image :src="developerLogo"
                               style="width:100px;" />
                        <div style="width:200px !important;">
                            <span class="text-sm text-grey-darkest">Powered by © 4Evolve wersja 2.0<br>rewizja {{ systemRevision }}</span>
                        </div>
                    </div>
                    <div class="flex justify-content-between align-self-center">
                        <span class="footer-custom-info">{{ premiumPeriod }}</span>
                        <span class="footer-custom-info"
                              style="padding-left:50px;color:rosybrown">{{ currentDate }}</span>
                        <span v-if="deputyName"
                              style="padding-left:50px"
                              class="deputy-title">{{ __('Replaced worker') }}:
                            <span class="deputy-name">{{ deputyName }}
                            </span>
                        </span>
                    </div>
                    <div class="flex flex-row flex-nowrap align-items-center">
                        <div class="flex flex-nowrap justify-content-start align-items-center"
                             style="width:500px">
                            <span v-if="isCreator"
                                  class="admin-area-in-footer"></span>

                            <Link v-ripple
                                  class="href-text"
                                  :aria-hidden="false"
                                  :href="checkRoute('dashboard-help', null, null)">
                            <span class="mx-2 footer-links">Pomoc</span>
                            </Link>

                            <Link v-ripple
                                  class="href-text"
                                  :aria-hidden="false"
                                  :href="checkRoute('dashboard-changelog', null, null)">
                            <span class="mx-2 footer-links">Changelog</span>
                            </Link>

                            <Link v-ripple
                                  class="href-text"
                                  :aria-hidden="false"
                                  :href="checkRoute('dashboard-privacy', null, null)">
                            <span class="mx-2 footer-links">Polityka prywatności</span>
                            </Link>
                        </div>

                        <div id='user-name-area'
                             style="text-wrap:nowrap">
                            <Link v-ripple
                                  :aria-hidden="false"
                                  :href="checkRoute('user-profile', { user_id: userId })">{{ userName }}</Link>
                        </div>
                        <div>
                            <Button icon="pi pi-save"
                                    severity="info"
                                    id="saveSessionIcon"
                                    text
                                    size="large"
                                    v-tooltip.top="__('Save session')"
                                    @click="saveSession" />
                            <Button icon="pi pi-trash"
                                    severity="warning"
                                    id="removeSessionIcon"
                                    text
                                    size="large"
                                    v-tooltip.top="__('Remove session')"
                                    @click="removeSession" />
                        </div>
                    </div>
                </div>
            </VFooter>

        </VLayout>

        <div class="layout-box">
            <slot></slot>
        </div>
        <flash-message />
    </div>
</template>

<script setup>
import Menubar from 'primevue/menubar';
import Button from 'primevue/button';
import { Link, router, usePage } from '@inertiajs/vue3'
import { ref, onMounted, onBeforeMount, watchEffect } from "vue";
import Image from '@helper/Image.vue'
import { initFunctions } from "../../init.js";
import { __, saveAppSession, removeAppSession, hasPermission, checkRoute, getStoreValue, setStoreValue, getServerValue, getFromRoute } from '@pages/Helper/utils.js';
import { menuLogo, developerLogo } from '@helper/constants.js'
import axios from 'axios'
import { formatPremiumPeriod } from '@pages/Helper/custom.js'

const menuItems = ref(null);
const unprotectedMenuItems = ref({});
const userName = ref(null);
const userId = ref(null);
const multiModuleApp = ref(false);
const systemRevision = ref(getStoreValue('systemRevision'));

const premiumPeriod = ref(null);
const currentDate = ref(null)
const deputyName = ref(null);
const deputyInformation = ref(null);
const page = usePage();
const isLoading = ref(false);
const isCreator = ref(false);

//------------------------------------------------------------------------------

watchEffect(async () => {
    let savedPremiumPeriod = null
    savedPremiumPeriod = getStoreValue('premiumPeriod')
    if (!savedPremiumPeriod) {
        savedPremiumPeriod = await getServerValue('premiumPeriod')
        if (savedPremiumPeriod?.hasOwnProperty('premiumPeriod')) {
            setStoreValue('premiumPeriod', savedPremiumPeriod.premiumPeriod)
            savedPremiumPeriod = savedPremiumPeriod.premiumPeriod
        }
    }

    if (savedPremiumPeriod) {
        premiumPeriod.value = formatPremiumPeriod(savedPremiumPeriod)
    }
});

// ------------------------------------------------------------------------------

watchEffect(async () => {
    let savedDeputyName = null
    savedDeputyName = getStoreValue('deputyName')
    if (!savedDeputyName) {
        const response = await getFromRoute("worker-deputy", {}, 'post');
        savedDeputyName = response.deputy_name
        if (savedDeputyName) {
            setStoreValue('savedDeputyName', savedDeputyName)
            deputyInformation.value = savedDeputyName
        } else {
            setStoreValue('savedDeputyName', null)
        }
    }

    if (savedDeputyName) {
        deputyName.value = savedDeputyName
    } else {
        deputyName.value = null
    }
});

//------------------------------------------------------------------------------

watchEffect(async () => {
    currentDate.value = page.props.currentDate;
});

// ------------------------------------------------------------------------------

onBeforeMount(async () => {
    const response = await axios.post(route("menu-content"));
    const backendData = await response.data;

    menuItems.value = backendData.menu;
    if (menuItems.value.length == 0) {
        console.error('Wybrany moduł (' + backendData.moduleSymbol + ') nie posiada pliku json z zawartością menu. Nastąpi przekierowanie do strony wyboru modułu')
        router.get(route('select-module'))
    }

    unprotectedMenuItems.value = backendData.unprotected;
    userName.value = backendData.userName;
    userId.value = backendData.userId;
    multiModuleApp.value = backendData.multiModuleApp;

    clearInterval(window.displaySessionInterval);
})

//------------------------------------------------------------------------------

onMounted(async () => {
    window.endOfSession = false
    window.showDisplaySessionTime = false;
    window.lastActivityTime = null;
    isLoading.value = true;
    await initFunctions();
    isLoading.value = false;
    isCreator.value = getStoreValue('isCreator')

    const elements = document.querySelectorAll('[aria-hidden]');
    elements.forEach(element => {
        element.setAttribute('aria-hidden', 'false');
    });
})

//------------------------------------------------------------------------------

const saveSession = () => {
    saveAppSession();
}

//------------------------------------------------------------------------------

const removeSession = () => {
    removeAppSession();
}

//------------------------------------------------------------------------------

const selectModule = () => {
    router.get(route('select-module', { module: getStoreValue('currentModule') }))
}

//------------------------------------------------------------------------------

</script>

<style scoped>
.v-layout {
    position: sticky;
    position: -webkit-sticky;
    z-index: 2;
    top: 0;
}

:deep(.p-menubar-start) {
    padding: 0 40px 0 20px;
    display: flex;
}

:deep(.p-menubar-end) {
    position: absolute;
    padding: 0 40px 0 20px;
    right: 0;
}

:deep(.p-menuitem-link) {
    align-items: flex-start;
}

:deep(.p-submenu-list) {
    padding: 0px;
    box-shadow: 0px 9px 15px -1px rgba(66, 68, 90, 1);
    -webkit-box-shadow: 0px 9px 15px -1px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 9px 15px -1px rgba(66, 68, 90, 1);
}

.admin-area-in-footer {
    color: red;
    background-color: yellow;
    margin: 0 5px;
    font-weight: normal;
    font-size: 10px;
}
</style>
