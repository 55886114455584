<template>
    <div style="display:flex;justify-content: center; align-items: center; height: 91vh;">
        <Transition v-if="isLoading"
                    name="spinner">
            <div id="cover-spin"></div>
        </Transition>

        <v-form name="formConditions"
                class="shadow-box"
                style="border:1px solid #e0e0e0; padding:10px; width:90%">
            <TabView scrollable
                     style="overflow: visible;"
                     :pt="{ panelContainer: { class: 'form-tab-panel' } }">
                <TabPanel :header="__('Basic parameters')"
                          headerClass="form-tab-header">
                    <div class="grid">
                        <FormMultiselect fieldName="worker_id"
                                         label="Workers"
                                         showClear
                                         size="3"
                                         :options="select?.allworkers"
                                         :filter="(event) => onFilter(event, 'allworkers')"
                                         v-model:field="conditions.worker_id" />

                        <FormInputToggleButton fieldName="is_worker"
                                               label="Is included?"
                                               size="1"
                                               :default="true"
                                               v-model:field="conditions.is_worker" />
                        <!-- </div>
                    <div class="grid"> -->
                        <FormMultiselect fieldName="status_id"
                                         label="Statuses"
                                         showClear
                                         size="3"
                                         :options="select?.statuses"
                                         :filtering="false"
                                         v-model:field="conditions.status_id" />

                        <FormInputToggleButton fieldName="is_status"
                                               label="Is included?"
                                               size="1"
                                               v-model:field="conditions.is_status" />
                        <!-- </div>
                    <div class="grid"> -->
                        <FormMultiselect fieldName="position_id"
                                         label="Positions"
                                         showClear
                                         size="3"
                                         :options="select?.positions"
                                         :filter="(event) => onFilter(event, 'positions')"
                                         v-model:field="conditions.position_id" />

                        <FormInputToggleButton fieldName="is_position"
                                               label="Is included?"
                                               size="1"
                                               v-model:field="conditions.is_position" />
                    </div>
                    <div class="grid">
                        <FormMultiselect fieldName="dept_id"
                                         label="Departments"
                                         showClear
                                         size="3"
                                         :options="select?.depts"
                                         :filter="(event) => onFilter(event, 'depts')"
                                         v-model:field="conditions.dept_id" />

                        <FormInputToggleButton fieldName="is_dept"
                                               label="Is included?"
                                               size="1"
                                               v-model:field="conditions.is_dept" />
                        <!-- </div>
                    <div class="grid"> -->
                        <FormMultiselect fieldName="event_id"
                                         label="Events"
                                         showClear
                                         size="3"
                                         :options="select?.hrevents"
                                         :filtering="false"
                                         v-model:field="conditions.event_id" />

                        <FormInputToggleButton fieldName="is_event"
                                               label="Is included?"
                                               size="1"
                                               v-model:field="conditions.is_event" />
                        <!-- </div>
                    <div class="grid"> -->
                        <FormMultiselect fieldName="premium_id"
                                         label="Premium groups"
                                         showClear
                                         size="3"
                                         :options="select?.premiums"
                                         :filtering="false"
                                         v-model:field="conditions.premium_id" />

                        <FormInputToggleButton fieldName="is_premium"
                                               label="Is included?"
                                               size="1"
                                               v-model:field="conditions.is_premium" />
                    </div>
                </TabPanel>
                <TabPanel :header="__('Managers and Masters')"
                          headerClass="form-tab-header">
                    <div class="grid">
                        <FormMultiselect fieldName="manager_assessed_id"
                                         label="Assessed manager"
                                         showClear
                                         size="3"
                                         :options="select?.realmanagers"
                                         :filter="(event) => onFilter(event, 'realmanagers')"
                                         v-model:field="conditions.manager_assessed_id" />

                        <FormInputToggleButton fieldName="is_manager_assessed"
                                               label="Is included?"
                                               size="1"
                                               v-model:field="conditions.is_manager_assessed" />
                        <!-- </div>
                    <div class="grid"> -->
                        <FormMultiselect fieldName="master_assessed_id"
                                         label="Assessed master"
                                         showClear
                                         size="3"
                                         :options="select?.realmanagers"
                                         :filter="(event) => onFilter(event, 'realmanagers')"
                                         v-model:field="conditions.master_assessed_id" />

                        <FormInputToggleButton fieldName="is_master_assessed"
                                               label="Is included?"
                                               size="1"
                                               v-model:field="conditions.is_master_assessed" />
                        <!-- </div>
                    <div class="grid"> -->
                        <FormMultiselect fieldName="master_passedaccept_id"
                                         label="Passed accept master"
                                         showClear
                                         size="3"
                                         :options="select?.realmanagers"
                                         :filter="(event) => onFilter(event, 'realmanagers')"
                                         v-model:field="conditions.master_passed_accept_id" />

                        <FormInputToggleButton fieldName="is_master_passed_accept"
                                               label="Is included?"
                                               size="1"
                                               v-model:field="conditions.is_master_passed_accept" />
                    </div>
                    <div class="grid">
                        <FormMultiselect fieldName="manager_passedapprove_id"
                                         label="Passed approve manager"
                                         showClear
                                         size="3"
                                         :options="select?.realmanagers"
                                         :filter="(event) => onFilter(event, 'realmanagers')"
                                         v-model:field="conditions.manager_passedapprove_id" />

                        <FormInputToggleButton fieldName="is_manager_passedapprove"
                                               label="Is included?"
                                               size="1"
                                               v-model:field="conditions.is_manager_passedapprove_id" />
                        <!-- </div>
                    <div class="grid"> -->
                        <FormMultiselect fieldName="manager_approved_id"
                                         label="Approved manager"
                                         showClear
                                         size="3"
                                         :options="select?.realmanagers"
                                         :filter="(event) => onFilter(event, 'realmanagers')"
                                         v-model:field="conditions.manager_approved_id" />

                        <FormInputToggleButton fieldName="is_manager_approved"
                                               label="Is included?"
                                               size="1"
                                               v-model:field="conditions.is_manager_approved_id" />
                        <!-- </div>
                    <div class="grid"> -->
                        <FormMultiselect fieldName="manager_rejected_id"
                                         label="Rejected manager"
                                         showClear
                                         size="3"
                                         :options="select?.realmanagers"
                                         :filter="(event) => onFilter(event, 'realmanagers')"
                                         v-model:field="conditions.manager_rejected_id" />

                        <FormInputToggleButton fieldName="is_manager_rejected"
                                               label="Is included?"
                                               size="1"
                                               v-model:field="conditions.is_manager_rejected_id" />
                    </div>
                    <div class="grid">
                        <FormMultiselect fieldName="manager_deputy_id"
                                         label="Deputy manager"
                                         showClear
                                         size="3"
                                         :options="select?.realmanagers"
                                         :filter="(event) => onFilter(event, 'realmanagers')"
                                         v-model:field="conditions.manager_deputy_id" />

                        <FormInputToggleButton fieldName="is_manager_deputy"
                                               label="Is included?"
                                               size="1"
                                               v-model:field="conditions.is_manager_deputy_id" />
                    </div>
                </TabPanel>
                <TabPanel :header="__('Dates')"
                          headerClass="form-tab-header">
                    <div class="grid">
                        <FormCalendar fieldName="sheet_date_from"
                                      label="Sheet date from"
                                      size="2"
                                      :isFocus="true"
                                      v-model:field="conditions.sheet_date_from" />
                        <FormCalendar fieldName="sheet_date_to"
                                      label="Sheet date to"
                                      size="2"
                                      v-model:field="conditions.sheet_date_to" />
                        <FormInputToggleButton fieldName="is_sheet_date"
                                               label="Is included?"
                                               size="2"
                                               v-model:field="conditions.is_sheet_date" />

                        <FormCalendar fieldName="passaccept_date_from"
                                      label="Passed accept date from"
                                      size="2"
                                      :isFocus="true"
                                      v-model:field="conditions.passaccept_date_from" />
                        <FormCalendar fieldName="passaccept_date_to"
                                      label="Passed accept date to"
                                      size="2"
                                      v-model:field="conditions.passaccept_date_to" />
                        <FormInputToggleButton fieldName="is_passaccept_date"
                                               label="Is included?"
                                               size="2"
                                               v-model:field="conditions.is_passaccept_date" />
                    </div>
                    <div class="grid">
                        <FormCalendar fieldName="passapprove_date_from"
                                      label="Passed approve date from"
                                      size="2"
                                      :isFocus="true"
                                      v-model:field="conditions.passapprove_date_from" />
                        <FormCalendar fieldName="passapprove_date_to"
                                      label="Passed approve date to"
                                      size="2"
                                      v-model:field="conditions.passapprove_date_to" />
                        <FormInputToggleButton fieldName="is_passapprove_date"
                                               label="Is included?"
                                               size="2"
                                               v-model:field="conditions.is_passapprove_date" />

                        <FormCalendar fieldName="assessment_date_from"
                                      label="Assessment date from"
                                      size="2"
                                      :isFocus="true"
                                      v-model:field="conditions.assessment_date_from" />
                        <FormCalendar fieldName="assessment_date_to"
                                      label="Assessment date to"
                                      size="2"
                                      v-model:field="conditions.assessment_date_to" />
                        <FormInputToggleButton fieldName="is_assessment_date"
                                               label="Is included?"
                                               size="1"
                                               v-model:field="conditions.is_assessment_date" />
                    </div>
                    <div class="grid">
                        <FormCalendar fieldName="approved_date_from"
                                      label="Approved date from"
                                      size="2"
                                      :isFocus="true"
                                      v-model:field="conditions.approved_date_from" />
                        <FormCalendar fieldName="approved_date_to"
                                      label="Approved date to"
                                      size="2"
                                      v-model:field="conditions.approved_date_to" />
                        <FormInputToggleButton fieldName="is_approved_date"
                                               label="Is included?"
                                               size="2"
                                               v-model:field="conditions.is_approved_date" />

                        <FormCalendar fieldName="rejected_date_from"
                                      label="Rejected date from"
                                      size="2"
                                      :isFocus="true"
                                      v-model:field="conditions.rejected_date_from" />
                        <FormCalendar fieldName="rejected_date_to"
                                      label="Rejected date to"
                                      size="2"
                                      v-model:field="conditions.rejected_date_to" />
                        <FormInputToggleButton fieldName="is_rejected"
                                               label="Is included?"
                                               size="2"
                                               v-model:field="conditions.is_rejected" />
                    </div>
                </TabPanel>
                <TabPanel :header="__('Others')"
                          headerClass="form-tab-header">
                    <div class="grid">
                        <FormDropdown fieldName="is_premium_right"
                                      label="Is premium right?"
                                      size="2"
                                      scrollHeight="100px"
                                      :showClear="true"
                                      :filtering="false"
                                      :options="yesNoOptions()"
                                      v-model:field="conditions.is_premium_right" />
                        <FormInputToggleButton fieldName="is_is_premium_right"
                                               label="Is included?"
                                               size="1"
                                               v-model:field="conditions.is_is_premium_right" />

                        <FormDropdown fieldName="is_rejected"
                                      label="Is rejected?"
                                      size="2"
                                      scrollHeight="100px"
                                      :showClear="true"
                                      :filtering="false"
                                      :options="yesNoOptions()"
                                      v-model:field="conditions.is_rejected" />
                        <FormInputToggleButton fieldName="is_is_rejected"
                                               label="Is included?"
                                               size="1"
                                               v-model:field="conditions.is_is_rejected" />

                        <FormDropdown fieldName="is_passed_to_sap"
                                      label="Is passed to SAP?"
                                      size="2"
                                      scrollHeight="100px"
                                      :showClear="true"
                                      :filtering="false"
                                      :options="yesNoOptions()"
                                      v-model:field="conditions.is_passed_to_sap" />
                        <FormInputToggleButton fieldName="is_passed_to_sap"
                                               label="Is included?"
                                               size="1"
                                               v-model:field="conditions.is_passed_to_sap" />
                    </div>
                    <div class="grid">
                        <FormInputToggleButton fieldName="is_rejected_count"
                                               label="Is rejected count included?"
                                               size="3"
                                               v-model:field="conditions.is_rejected_count" />
                        <FormInputToggleButton fieldName="is_premium_base_limit_sheet"
                                               label="Is premium base limit included?"
                                               size="3"
                                               v-model:field="conditions.is_premium_base_limit_sheet" />
                        <FormInputToggleButton fieldName="is_multiplier_premium_sheet"
                                               label="Is multipler premium included?"
                                               size="3"
                                               v-model:field="conditions.is_multiplier_premium_sheet" />
                    </div>
                </TabPanel>
            </TabView>
            <div class="grid">
                <Button :label="__('Create')"
                        icon="pi pi-check"
                        class="p-button-success"
                        style="margin:10px 5px;"
                        @click="createReport"></Button>
                <Button :label="__('Cancel')"
                        icon="pi pi-times"
                        class="p-button-secondary"
                        style="margin:10px 5px;"
                        @click="cancelOperation">
                </Button>
            </div>
        </v-form>
    </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormMultiselect from "@crud/FormMultiselect.vue";
import FormCalendar from "@crud/FormCalendar.vue";
import FormInputToggleButton from "@crud/FormInputToggleButton.vue";
import FormDropdown from "@crud/FormDropdown.vue";
import Button from 'primevue/button';
import { router } from '@inertiajs/vue3';
import { __, makeReport } from '@pages/Helper/utils.js'
import { prepareSelect, onFilterService, validation, yesNoOptions } from '@pages/Helper/crud_utils.js'

// -----------------------------------------------------

const props = defineProps({
    reportParams: Object,
    conditions: { type: Object, default: {} }
})

const isLoading = ref(true);
const options = ["allworkers", "statuses", "positions", "depts", "hrevents", "premiums", "realmanagers"];
const select = ref(null);

let conditions = ref(props.conditions)

// -----------------------------------------------------

onBeforeMount(async () => {
    isLoading.value = true;
    select.value = await prepareSelect(options, null, conditions)
    isLoading.value = false;
});

// -----------------------------------------------------

const createReport = () => {
    if (validation(conditions.value, 'formConditions')) {
        conditions.value = { ...conditions.value, ...{ is_criterion_name: true, is_type: true, is_weight: true, is_assessment: true, is_result_assessment: true } }
        makeReport(props.reportParams, conditions, isLoading)
    }
}

// -----------------------------------------------------

const cancelOperation = () => {
    router.get(route('report-index'));
}

// -----------------------------------------------------

const onFilter = async (event, option) => {
    await onFilterService(event, option, select, isLoading, conditions, null, options);
}

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
