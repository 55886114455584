<template>
    <VLayout class="rounded-md flex-column">
        <VMain>
            <div class="layout-box">
                <slot></slot>
            </div>
        </VMain>
    </VLayout>
</template>

<script setup>
import { onBeforeMount, nextTick } from "vue";
import { initFunctions } from "../../init.js";

onBeforeMount(async () => {
    await nextTick()
    initFunctions();
})
</script>

<style scoped></style>
