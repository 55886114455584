<template>
    <div>
        <Dialog modal
                :visible="isSetManagersModalOpen"
                v-model:visible="isSetManagersModalOpen"
                :header="__('Set managers')"
                :style="{ width: '500px' }">
            <p style="padding-bottom:15px;font-size:16px; font-weight:bold;color:cadetblue">{{ __('Managers will be assigned based on their roles and locations in the organizational structure') }}</p>

            <SetManagersForm v-model:isSetManagersModalOpen="isSetManagersModalOpen"
                             v-model:selectedRows="selectedRows">
            </SetManagersForm>
        </Dialog>

        <v-view :tableData="tableData"
                :tableProps="tableProps"
                :modalProps="modalProps"
                :columns="columns"
                :routes="routes"
                :initialSort="initialSort"
                :buttonsVisibility="buttonsVisibility"
                :renderColumnValue="renderColumnValue"
                :rowButtonAction="rowButtonAction"
                :tooltipColumns="tooltipColumns"
                v-model:selectedRows="selectedRows">

            <template #buttons="">
                <div v-if="canSetManagers">
                    <Button @click.stop="openSetManagersWindow()"
                            style="background-color:lightsalmon;color:black;margin:0 7px 0 25px;white-space:nowrap"
                            severity="info">
                        <i class="fa-solid fa-users-viewfinder"
                           style="margin-right:5px;font-size:18px"></i>
                        {{ __('Set managers') }}
                    </Button>
                </div>
            </template>

            <template #crud="formProps">
                <UserForm :formProps="formProps"> </UserForm>
            </template>
            <template #search="searchProps">
                <UserSearch :searchProps="searchProps"> </UserSearch>
            </template>
        </v-view>
    </div>
</template>

<script setup>
import { ref } from "vue";
import UserForm from "./UserForm.vue";
import UserSearch from "./UserSearch.vue";
import ConfirmDialog from '@pages/Crud/ConfirmDialog.vue'
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import SetManagersForm from './SetManagersForm.vue'
import { rowButtonAction } from "@pages/Helper/crud_utils.js";
import { __, screenMessage, getFromRoute } from "@pages/Helper/utils.js";

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    tooltipColumns: Object,
    initialSort: Object,
    buttonsVisibility: Object,
    canSetManagers: Boolean,
});

const selectedRows = ref([]);
const isSetManagersModalOpen = ref(false)

//------------------------------------------------------------------------------

const openSetManagersWindow = () => {
    if (selectedRows.value.length == 0) {
        const message = {
            message: "No worker has been selected",
            title: "Worker selection error",
            type: "error",
            position: "center",
            timeout: 5,
        };
        screenMessage(message);
        return false;
    }

    isSetManagersModalOpen.value = true
}

//------------------------------------------------------------------------------

const renderColumnValue = (field, value) => {
    switch (field) {
        case "is_locked":
            return value == 1 ? '<span class="flex justify-content-center"><i class="fa-solid fa-lock" style="font-size:20px;color:red;"></i></span>' : "";
        case "is_active":
            return value == 1 ? '<span class="flex justify-content-center"><i class="fa-solid fa-person-running" style="font-size:20px;color:royalblue;"></i></span>' : "";
        case "is_submit_to_approval":
            return value == 1 ? '<span class="flex justify-content-center"><i class="fa-regular fa-envelope" style="font-size:20px;color:olive;"></i></span>' : "";
        case "is_final_approval":
            return value == 1 ? '<span class="flex justify-content-center"><i class="fa-solid fa-flag-checkered" style="font-size:20px;color:green;"></i></span>' : "";

        default:
            return value;
    }
};

//------------------------------------------------------------------------------

</script>

<style lang="scss" scoped></style>
