<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form :name="formProps.formName"
            @keyup.enter="autoSave = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Basic data')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="status_category"
                                   label="Category"
                                   size="6"
                                   validation="required"
                                   :isFormDisabled="!isCreator"
                                   :isFocus="true"
                                   v-model:field="crud.status_category" />

                    <FormInputText fieldName="status_symbol"
                                   label="Symbol"
                                   size="6"
                                   validation="required"
                                   :isFormDisabled="!isCreator"
                                   v-model:field="crud.status_symbol" />
                </div>
                <div class="grid">
                    <FormInputText fieldName="status_name"
                                   label="Name"
                                   size="6"
                                   validation="required"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.status_name" />

                    <FormInputNumber fieldName="order"
                                     label="Order"
                                     size="2"
                                     :isFormDisabled="isFormDisabled"
                                     v-model:field="crud.order" />
                    <FormColorPicker fieldName="text_color"
                                     label="Text color"
                                     size="2"
                                     :isFormDisabled="isFormDisabled"
                                     v-model:field="crud.text_color" />

                    <FormColorPicker fieldName="background_color"
                                     label="Background color"
                                     size="2"
                                     :isFormDisabled="isFormDisabled"
                                     v-model:field="crud.background_color" />
                </div>
                <div class="grid">
                    <FormEditor fieldName="description"
                                label="Description"
                                :editorStyle="{ height: '120px' }"
                                :isFormDisabled="isFormDisabled"
                                v-model:field="crud.description" />

                </div>
            </TabPanel>

            <TabPanel :header="__('Changelog')"
                      headerClass="form-tab-header">
                <ChangeLog :log="crud?.log" />
            </TabPanel>
        </TabView>

        <div class="grid">
            <FormButtons :formProps="props.formProps"
                         :sourceForm="crud"
                         :validation="validation"
                         :buttonsVisibility="buttonsVisibility"
                         v-model:isFormDisabled="isFormDisabled"
                         v-model:autoSave="autoSave">
            </FormButtons>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { validation, onFilterService, initForm } from '@pages/Helper/crud_utils.js'
import FormInputText from '@crud/FormInputText.vue'
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormColorPicker from '@crud/FormColorPicker.vue'
import FormEditor from '@crud/FormEditor.vue'
import FormButtons from '@crud/FormButtons.vue'
import ChangeLog from '@crud/ChangeLog.vue'
import { __, getStoreValue } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})

const isLoading = ref(true);
const isFormDisabled = ref(false)
const isCreator = ref(false)
const autoSave = ref(false)

const crud = ref({})
const buttonsVisibility = ref(null);

// -----------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, null, null, buttonsVisibility)
    isCreator.value = getStoreValue('isCreator')
})

// -----------------------------------------------------

const onFilter = async (event, option) => {
    await onFilterService(event, option, select, isLoading, null, params)
}

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
