<template>
    <v-form :name="props.searchProps.formName"
            @keyup.enter="autoSearch = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Search condition')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="last_name"
                                   label="Last name"
                                   size="3"
                                   :isFocus="true"
                                   v-model:field="search.last_name" />

                    <FormInputText fieldName="first_name"
                                   label="First name"
                                   size="3"
                                   v-model:field="search.first_name" />

                    <FormInputText fieldName="email"
                                   label="Email"
                                   size="3"
                                   v-model:field="search.email" />

                    <FormInputMask fieldName="phone"
                                   label="Phone"
                                   size="3"
                                   mask="(+99) 999-999-999"
                                   placeholder="(+99) 999-999-999"
                                   v-model:field="search.phone" />
                </div>
                <div class="grid">
                    <FormDropdown fieldName="is_locked"
                                  label="Is user locked?"
                                  size="2"
                                  scrollHeight="100px"
                                  :showClear="true"
                                  :filtering="false"
                                  :options="yesNoOptions()"
                                  v-model:field="search.is_locked" />

                    <FormDropdown fieldName="is_active"
                                  label="Is active user?"
                                  size="2"
                                  scrollHeight="100px"
                                  :showClear="true"
                                  :filtering="false"
                                  :options="yesNoOptions()"
                                  v-model:field="search.is_active" />

                    <FormDropdown fieldName="is_password_policy"
                                  label="Is password policy?"
                                  size="2"
                                  scrollHeight="100px"
                                  :showClear="true"
                                  :filtering="false"
                                  :options="yesNoOptions()"
                                  v-model:field="search.is_password_policy" />

                    <FormDropdown fieldName="is_final_approval"
                                  label="Is final approval?"
                                  size="3"
                                  scrollHeight="100px"
                                  :showClear="true"
                                  :filtering="false"
                                  :options="yesNoOptions()"
                                  v-model:field="search.is_final_approval" />

                    <FormDropdown fieldName="is_submit_to_approval"
                                  label="Is submit approval?"
                                  size="3"
                                  scrollHeight="100px"
                                  :showClear="true"
                                  :filtering="false"
                                  :options="yesNoOptions()"
                                  v-model:field="search.is_submit_to_approval" />

                    <FormMultiselect fieldName="role_id"
                                     label="Role"
                                     size="6"
                                     showClear
                                     :options="select?.roles"
                                     :filter="(event) => onFilter(event, 'roles')"
                                     v-model:field="search.roles" />
                </div>
            </TabPanel>
        </TabView>


        <div class="grid">
            <SearchButtons :searchProps="searchProps"
                           :search="search"
                           v-model:autoSearch="autoSearch" />
        </div>
    </v-form>
</template>

<script setup>
import { ref } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import FormInputText from "@crud/FormInputText.vue";
import FormInputMask from "@crud/FormInputMask.vue";
import FormDropdown from "@crud/FormDropdown.vue";
import FormMultiselect from "@crud/FormMultiselect.vue";

import SearchButtons from '@crud/SearchButtons.vue'
import { prepareSearch, yesNoOptions } from '@pages/Helper/crud_utils.js'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    searchProps: Object,
})
const autoSearch = ref(false)

const options = ["roles"];
const select = ref(null);

let search = ref({})

prepareSearch(search, props, options, select)

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
