<template>
    <div>
        <Transition v-if="isLoading"
                    name="spinner">
            <div id="cover-spin"></div>
        </Transition>
        <v-form>
            <FormInputToggleButton fieldName="is_replace_managers"
                                   label="Replace currently assigned managers?"
                                   style="color:red"
                                   v-model:field="is_replace_managers" />

            <div class="grid"
                 style="margin-top:30px">
                <Button severity="success"
                        style="margin:0 8px"
                        @click="setManagers">
                    <i class="fa-solid fa-plus"
                       style="margin-right:5px;font-size:18px"></i>
                    {{ __('Set managers') }}
                </Button>
                <Button severity="secondary"
                        style="margin:0 8px"
                        @click="cancel">
                    <i class="fa-solid fa-ban"
                       style="margin-right:5px;font-size:18px"></i>
                    {{ __('Cancel') }}
                </Button>
            </div>
        </v-form>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import Button from 'primevue/button';
import FormInputToggleButton from '@crud/FormInputToggleButton.vue'
import { __, screenMessage, getFromRoute } from '@pages/Helper/utils.js'

// -----------------------------------------------------

const isSetManagersModalOpen = defineModel('isSetManagersModalOpen')
const selectedRows = defineModel('selectedRows')
const isLoading = ref(false)
const is_replace_managers = ref(false)

// -----------------------------------------------------

const cancel = () => {
    isSetManagersModalOpen.value = false
}
// -----------------------------------------------------

const setManagers = async () => {
    isLoading.value = true
    const response = await getFromRoute('user-set-managers', {
        selectedRows: selectedRows.value.map(row => {
            return row.id
        }),
        is_replace_managers: is_replace_managers.value
    }, 'post')
    selectedRows.value = []
    isLoading.value = false
    isSetManagersModalOpen.value = false

    if (response.status == 'success') {
        screenMessage({ message: response.message, title: "Information", type: "info", position: "center", timeout: 10 });
    } else {
        screenMessage({ message: response.message, title: "Information", type: "error", position: "center", timeout: 15 });
    }
}

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
