<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form :name="formProps.formName"
            @keyup.enter="autoSave = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Basic data')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="premium_name"
                                   label="Premium group name"
                                   size="2"
                                   validation="required"
                                   :isFormDisabled="isFormDisabled"
                                   :isFocus="true"
                                   v-model:field="crud.premium_name" />

                    <FormInputText fieldName="symbol"
                                   label="Symbol"
                                   size="1"
                                   validation="required"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.symbol" />

                    <FormInputNumber fieldName="year"
                                     label="Year"
                                     size="1"
                                     validation="required"
                                     :default="new Date().getFullYear()"
                                     :useGrouping="false"
                                     :min="2010"
                                     :minFractionDigits="0"
                                     :maxFractionDigits="0"
                                     :isFormDisabled="isFormDisabled"
                                     v-model:field="crud.year" />

                    <FormDropdown fieldName="assessment_period"
                                  label="Assessment period"
                                  size="1"
                                  :setFirst="true"
                                  :filtering="false"
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.assessment_periods"
                                  v-model:field="crud.assessment_period" />

                    <FormInputToggleButton fieldName="is_quality_criteria"
                                           label="Quality criteria exists?"
                                           size="2"
                                           :isFormDisabled="isFormDisabled"
                                           v-model:field="crud.is_quality_criteria" />

                    <FormInputNumber v-if="crud.is_quality_criteria"
                                     fieldName="sum_weights_min"
                                     label="Min. weight sum"
                                     size="2"
                                     :min="0"
                                     :minFractionDigits="0"
                                     :maxFractionDigits="0"
                                     :isFormDisabled="isFormDisabled"
                                     v-model:field="crud.sum_weights_min" />

                    <FormInputToggleButton v-if="crud.is_quality_criteria"
                                           fieldName="is_weights_change"
                                           label="Weight change possibility exists?"
                                           size="3"
                                           :isFormDisabled="isFormDisabled"
                                           v-model:field="crud.is_weights_change" />

                </div>
                <div class="grid">
                    <FormInputToggleButton fieldName="is_premium_right"
                                           label="Premium right exists?"
                                           size="2"
                                           :isFormDisabled="isFormDisabled"
                                           v-model:field="crud.is_premium_right" />

                    <FormInputToggleButton fieldName="is_permanent_tasks"
                                           label="Permanent tasks exists?"
                                           size="2"
                                           :isFormDisabled="isFormDisabled"
                                           v-model:field="crud.is_permanent_tasks" />

                    <FormInputToggleButton fieldName="is_premium_targets"
                                           label="Premium targets exists?"
                                           size="2"
                                           :isFormDisabled="isFormDisabled"
                                           v-model:field="crud.is_premium_targets" />

                    <FormInputNumber v-if="crud.is_premium_targets"
                                     fieldName="count_targets_min"
                                     label="Min. target count"
                                     size="3"
                                     :min="0"
                                     :minFractionDigits="0"
                                     :maxFractionDigits="0"
                                     :isFormDisabled="isFormDisabled"
                                     v-model:field="crud.count_targets_min" />

                    <FormInputNumber v-if="crud.is_premium_targets"
                                     fieldName="count_targets_max"
                                     label="Max. target count"
                                     size="3"
                                     :min="0"
                                     :minFractionDigits="0"
                                     :maxFractionDigits="0"
                                     :isFormDisabled="isFormDisabled"
                                     v-model:field="crud.count_targets_max" />
                </div>
                <div class="grid">

                    <FormInputText fieldName="target_setting_to"
                                   label="Settings premium targets to"
                                   size="4"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.target_setting_to" />

                    <FormInputText fieldName="edit_finish_to"
                                   label="Finish editing to"
                                   size="4"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.edit_finish_to" />

                    <FormInputText fieldName="assessment_from"
                                   label="Make assessment from"
                                   size="4"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.assessment_from" />
                </div>
                <div class="grid">

                    <FormInputToggleButton fieldName="is_generate_sheets"
                                           label="Automatically generate sheets exists?"
                                           size="3"
                                           :isFormDisabled="isFormDisabled"
                                           v-model:field="crud.is_generate_sheets" />

                    <FormDropdown v-if="crud.is_generate_sheets"
                                  fieldName="status_id"
                                  label="State of generated sheet"
                                  size="2"
                                  showClear
                                  :filtering="false"
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.statuses"
                                  v-model:field="crud.status_id" />

                    <FormInputToggleButton fieldName="is_copying_sheets"
                                           label="Copying sheets possibility exists?"
                                           size="3"
                                           :isFormDisabled="isFormDisabled"
                                           v-model:field="crud.is_copying_sheets" />

                    <FormDropdown fieldName="multiplier_premium"
                                  label="Calculation base"
                                  size="2"
                                  :setFirst="true"
                                  :filtering="false"
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.multiplier_premiums"
                                  v-model:field="crud.multiplier_premium" />

                    <FormInputNumber fieldName="premium_base_limit"
                                     label="Premium base/limit"
                                     size="2"
                                     validation="required"
                                     :min="0"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     :isFormDisabled="isFormDisabled"
                                     v-model:field="crud.premium_base_limit" />
                </div>
                <div class="grid">

                    <FormEditor fieldName="description"
                                label="Description"
                                :editorStyle="{ height: '120px' }"
                                :isFormDisabled="isFormDisabled"
                                v-model:field="crud.description" />

                </div>
            </TabPanel>

            <TabPanel v-if="crud.is_quality_criteria"
                      :header="__('Quality criterions')"
                      headerClass="form-tab-header">
                <FormRepeaterTable v-model:record="crud.premiumCriterions"
                                   :keys="{ 'criterion_name': null, 'weight': 0 }"
                                   :disabled="isFormDisabled"
                                   :isFirstRowEmpty="false"
                                   :isCopyButton="false"
                                   repeaterStyle="margin-top:5px;width:50%"
                                   name="premiumCriterions"
                                   :title="[__('Criterium name'), __('Weight')]">
                    <template #repeat="slotProps">
                        <td :style="slotProps.cellStyle"
                            style="width:70%;min-width:200px">
                            <FormDropdown fieldName="criterion_name"
                                          size="2"
                                          scrollHeight="200px"
                                          class="w-full"
                                          :inRepeater="true"
                                          :filtering="false"
                                          :isFormDisabled="isFormDisabled"
                                          :options="select?.criterion_names"
                                          v-model:field="slotProps.record.criterion_name" />
                        </td>
                        <td :style="slotProps.cellStyle">
                            <FormDropdown :style="{ width: '120px' }"
                                          fieldName="weight"
                                          :isFormDisabled="isFormDisabled"
                                          :filtering="false"
                                          :options="select?.weights"
                                          :change="calcCriterionsWeights"
                                          v-model:field="slotProps.record.weight" />

                        </td>
                    </template>
                    <template #footer="slotProps">
                        <td :style="slotProps.cellStyle"><span name="criterions-sum-footer"></span></td>
                        <td :style="slotProps.cellStyle"
                            class="sheet-sum"
                            style="text-align:right;">{{ criterionsWeigthSum }}%</td>
                        <td :style="slotProps.cellStyle"></td>
                    </template>
                </FormRepeaterTable>
            </TabPanel>

            <TabPanel v-if="crud.is_permanent_tasks"
                      :header="__('Permanent tasks')"
                      headerClass="form-tab-header">
                <FormRepeaterTable v-model:record="crud.premiumTasks"
                                   :keys="{ 'task_name': null, 'weight': 0 }"
                                   :disabled="isFormDisabled"
                                   :isFirstRowEmpty="false"
                                   :isCopyButton="false"
                                   repeaterStyle="margin-top:5px;width:90%;"
                                   name="premiumTasks"
                                   :title="[__('Task name'), __('Weight')]">
                    <template #repeat="slotProps">
                        <td :style="slotProps.cellStyle"
                            style="width:90%;min-width:150px">
                            <FormInputText class="w-full"
                                           fieldName="task_name"
                                           :isFormDisabled="isFormDisabled"
                                           :inRepeater="true"
                                           v-model:field="slotProps.record.task_name" />
                        </td>
                        <td :style="slotProps.cellStyle"
                            style="width:10%;">
                            <FormDropdown :style="{ width: '120px' }"
                                          fieldName="weight"
                                          :isFormDisabled="isFormDisabled"
                                          :filtering="false"
                                          :options="select?.weights"
                                          :change="calcTasksWeights"
                                          v-model:field="slotProps.record.weight" />
                        </td>
                    </template>
                    <template #footer="slotProps">
                        <td :style="slotProps.cellStyle"><span name="tasks-sum-footer"></span></td>
                        <td :style="slotProps.cellStyle"
                            class="sheet-sum"
                            style="text-align:right;">{{ tasksWeightSum }}%</td>
                        <td :style="slotProps.cellStyle"></td>
                    </template>
                </FormRepeaterTable>
            </TabPanel>

            <TabPanel v-if="crud.log"
                      :header="__('Changelog')"
                      headerClass="form-tab-header">
                <ChangeLog :log="crud?.log" />
            </TabPanel>

        </TabView>

        <div class="grid">
            <FormButtons :formProps="props.formProps"
                         :sourceForm="crud"
                         :validation="validation"
                         :buttonsVisibility="buttonsVisibility"
                         :beforeSave="beforeSave"
                         v-model:isFormDisabled="isFormDisabled"
                         v-model:autoSave="autoSave">
            </FormButtons>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { validation, onFilterService, initForm } from '@pages/Helper/crud_utils.js'
import FormInputText from '@crud/FormInputText.vue'
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormInputToggleButton from '@crud/FormInputToggleButton.vue'
import FormDropdown from '@crud/FormDropdown.vue'
import FormEditor from '@crud/FormEditor.vue'
import FormButtons from '@crud/FormButtons.vue'
import FormRepeaterTable from '@crud/FormRepeaterTable.vue'
import ChangeLog from '@crud/ChangeLog.vue'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})

const isLoading = ref(true);
const isFormDisabled = ref(false)
const autoSave = ref(false)

const options = ['statuses', 'assessment_periods', 'multiplier_premiums', 'criterion_names', 'weights']
const select = ref(null)
const crud = ref({})
const buttonsVisibility = ref(null);

const criterionsWeigthSum = ref(0)
const tasksWeightSum = ref(0)

// -----------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, options, select, buttonsVisibility)
    recalculateWeights()
})

// -----------------------------------------------------

const calcTasksWeights = () => {
    tasksWeightSum.value = crud.value?.premiumTasks?.reduce((sum, task) => sum + (task.weight || 0), 0)
}

// -----------------------------------------------------

const calcCriterionsWeights = () => {
    criterionsWeigthSum.value = crud.value?.premiumCriterions?.reduce((sum, criterion) => sum + (criterion.weight || 0), 0)
}

// -----------------------------------------------------

const recalculateWeights = () => {
    calcTasksWeights()
    calcCriterionsWeights()
}

// -----------------------------------------------------

const beforeSave = () => {
    if (!crud.value.is_quality_criteria) {
        crud.value.premiumCriterions = []
        crud.value.sum_weights_min = 0
        crud.value.is_weights_change = false
    }
    if (!crud.value.is_permanent_tasks) {
        crud.value.premiumTasks = []
    }
    if (!crud.value.is_premium_targets) {
        crud.value.count_targets_min = 0
        crud.value.count_targets_max = 0
    }
    if (!crud.value.is_generate_sheets) {
        crud.value.status_id = null
    }

    return crud.value
}

</script>

<style lang="scss" scoped></style>
