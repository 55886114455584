<template>
    <div>
        <v-view :tableData="tableData"
                :tableProps="tableProps"
                :modalProps="modalProps"
                :columns="columns"
                :routes="routes"
                :initialSort="initialSort"
                :renderColumnValue="renderColumnValue"
                :rowButtonAction="rowButtonAction"
                :buttonsVisibility="buttonsVisibility">
            <template #buttons="formProps">
                <div :formProps="formProps"></div>
            </template>

            <template #crud="formProps">
                <SheetForm :formProps="formProps"> </SheetForm>
            </template>
            <template #search="searchProps">
                <SheetSearch :searchProps="searchProps"> </SheetSearch>
            </template>
        </v-view>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useForm } from '@inertiajs/vue3'
import Button from 'primevue/button';
import SheetForm from "./SheetForm.vue";
import SheetSearch from "./SheetSearch.vue";
import ConfirmDialog from '@pages/Crud/ConfirmDialog.vue'
import Dialog from 'primevue/dialog';
import { __, screenMessage } from "@pages/Helper/utils.js";
import { rowButtonAction } from "@pages/Helper/crud_utils.js";

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    tooltipColumns: Object,
    initialSort: Object,
    buttonsVisibility: Object,
});

// const isConfirmationOpen = ref(false)
// const recordSelectedToPass = ref(null)

// const isGenerateSheetsModalOpen = ref(false)
// const generateSheetsFormProps = ref({})

//------------------------------------------------------------------------------

const renderColumnValue = (field, value) => {
    return value;
};

//------------------------------------------------------------------------------

//------------------------------------------------------------------------------


</script>

<style lang="scss" scoped></style>
