import { router, usePage } from '@inertiajs/vue3'
import { useAppStore } from "@stores/appstate.js";
import { appName as constantAppName, appCaption as constantAppCaption } from './constants.js';
import { usePrimeVue } from "primevue/config";
import { onErrorService } from "@pages/Helper/crud_utils.js";
import axios from 'axios';

// -----------------------------------------------------------------------------------------------------------

export const getAppName = () => {
    const page = usePage()
    return page.props?.appName ?? constantAppName;
}

// -----------------------------------------------------------------------------------------------------------

export const getAppCaption = () => {
    const page = usePage()
    // console.log(page)
    return page.props?.appCaption ?? constantAppCaption;
}

// -----------------------------------------------------------------------------------------------------------

export const getSystemRevision = () => {
    const page = usePage()
    return page.props?.systemRevision ?? '';
}

// -----------------------------------------------------------------------------------------------------------

export const getLocale = () => {
    const page = usePage()
    return page.props?.appLocale;
}

// -----------------------------------------------------------------------------------------------------------

export const setLocale = () => {
    const page = usePage();
    const locale = page.props.appLocale;
    sessionStorage.removeItem("locale");
    sessionStorage.setItem("locale", locale);
};

// ----------------------------------------------------------------------------------------------------

export const __ = (msg, replacements = null) => {
    let objTran = null;
    if (!window.objTran) {
        objTran = getSessionValue("translations");
        window.objTran = JSON.parse(objTran);
        objTran = window.objTran;
    } else {
        objTran = window.objTran;
    }

    if (objTran) {
        let tran = objTran[msg] ?? msg;
        if (replacements) {
            for (const [key, value] of Object.entries(replacements)) {
                tran = tran.replace(`:${key}`, value);
            }
        }
        return tran;
    } else {
        return msg;
    }
};

// -----------------------------------------------------------------------------------------------------------

export function isObjectEmpty(obj) {
    if (!obj) {
        return null;
    }
    return Object.keys(obj).length === 0;
}

//----------------------------------------------------------------------

export const today = (withTime = false, withSeconds = false) => {
    const date = new Date();

    let day = String(date.getDate()).padStart(2, "0");
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let year = date.getFullYear();
    let hour = String(date.getHours()).padStart(2, "0");
    let minute = String(date.getMinutes()).padStart(2, "0");
    let second = String(date.getSeconds()).padStart(2, "0");

    let today = null;
    if (!withTime) {
        today = year + "-" + month + "-" + day;
    } else {
        if (!withSeconds) {
            today = year + "-" + month + "-" + day + " " + hour + ":" + minute;
        } else {
            today = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        }
    }

    return today;
};

//----------------------------------------------------------------------------

export const msToHours = (duration) => {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
};

// ----------------------------------------------------------------------------------------------------

export const setSessionValue = (key, value) => {
    let appName = getAppName()
    let appData = JSON.parse(sessionStorage.getItem(appName)) || {};
    appData[key] = value;
    sessionStorage.setItem(appName, JSON.stringify(appData));
};

// -----------------------------------------------------------------------------------------------------------

export function getSessionValue(key) {
    let arrKey = key.split(".");
    let appName = getAppName()

    try {
        var sessionData = JSON.parse(sessionStorage.getItem(appName));
        arrKey.forEach((elem) => {
            if (sessionData && sessionData.hasOwnProperty(elem)) {
                sessionData = sessionData[elem];
            } else {
                sessionData = null;
            }
        });
        return sessionData;
    } catch (e) {
        return null;
    }
}

//----------------------------------------------------------------------

export const removeSessionValue = (key) => {
    let appName = getAppName()
    let appData = JSON.parse(sessionStorage.getItem(appName)) || {};
    delete appData[key];
    sessionStorage.setItem(appName, JSON.stringify(appData));
};

// -----------------------------------------------------------------------------------------------------------

export const setServerValue = async (key, value) => {
    await getFromRoute('set-session', { [key]: value }, 'post');
}

// -----------------------------------------------------------------------------------------------------------

export const getServerValue = async (keys = null) => {
    if (!Array.isArray(keys)) {
        keys = [keys];
    }
    let values = await getFromRoute('get-session', [...keys], 'get');
    return values;
}

// -----------------------------------------------------------------------------------------------------------

export const removeServerValue = async (key) => {
    await getFromRoute('remove-session', { key }, 'post');
}

// -----------------------------------------------------------------------------------------------------------

export const setStoreValue = (key, value) => {
    const store = useAppStore();
    store.$patch({ [key]: value });
};

// -----------------------------------------------------------------------------------------------------------

export const getStoreValue = (key) => {
    const store = useAppStore();
    return store[key];
};

// -----------------------------------------------------------------------------------------------------------

export const removeStoreValue = (key) => {
    const store = useAppStore();
    store.$patch((state) => {
        delete state[key];
    });
};

// -----------------------------------------------------------------------------------------------------------

export const saveAppSession = async () => {
    let appName = getAppName();
    let sessionStorageTmp = JSON.parse(sessionStorage.getItem(appName))
    delete sessionStorageTmp?.translations;

    const prefix = appName + '-';
    const suffixesToRemove = ['-custom-link-data']
    let viewStates = {};

    for (let i = 0; i < sessionStorage.length; i++) {
        let key = sessionStorage.key(i);
        if (key.startsWith(prefix) && !suffixesToRemove.some(suffix => key.endsWith(suffix))) {
            viewStates[key] = JSON.parse(sessionStorage.getItem(key));
        }
    }

    let storage = {
        local: { ...localStorage },
        session: { ...sessionStorageTmp },
        views: { ...viewStates },
    }

    // router.post(route('save-session'), storage)
    document.querySelector('#saveSessionIcon').style.color = 'red'
    await getFromRoute('save-session', storage, 'post')
    document.querySelector('#saveSessionIcon').style.color = '#17ABD0'
}

// -----------------------------------------------------------------------------------------------------------

export const removeAppSession = async () => {
    let appName = getAppName();
    const prefix = appName + '-';

    for (let i = 0; i < sessionStorage.length; i++) {
        let key = sessionStorage.key(i);
        if (key.startsWith(prefix)) {
            sessionStorage.removeItem(key);
        }
    }

    document.querySelector('#removeSessionIcon').style.color = 'red'
    await getFromRoute('remove-session-all', {}, 'get')
    document.querySelector('#removeSessionIcon').style.color = '#F8C638'
}

// -----------------------------------------------------------------------------------------------------------

export const screenMessage = (objMessage = null, cleanFlash = true) => {
    const page = usePage();
    if (objMessage) {
        if (!objMessage.hasOwnProperty("type")) {
            objMessage.type = "info";
        }
        if (!objMessage.hasOwnProperty("title")) {
            objMessage.title = "Information";
        }
        if (!objMessage.hasOwnProperty("message")) {
            objMessage.message = "No message";
        }
        if (!objMessage.hasOwnProperty("position")) {
            objMessage.position = "top-right";
        }
        if (!objMessage.hasOwnProperty("timeout")) {
            objMessage.timeout = 5;
        }

        page.props.flash.message = objMessage;
    }

    setTimeout(() => {
        if (cleanFlash) {
            page.props.flash = {};
        }
    }, 0);
}

// -----------------------------------------------------------------------------------------------------------

export const getField = async (table, field, where) => {
    try {
        const response = await axios.post(route('get-field'), {
            table,
            field,
            where
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        });
        const value = await response.data;
        return value;
    } catch (error) {
        console.error(error);
        return null;
    }
};

// -----------------------------------------------------------------------------------------------------------

export const getRecord = async (table, fields = null, where) => {
    try {
        const response = await axios.post(route('get-record'), {
            table,
            fields,
            where
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        });
        const value = await response.data;
        return value;
    } catch (error) {
        console.error(error);
        return null;
    }
};

// -----------------------------------------------------------------------------------------------------------

export const getFromRoute = async (routeName, parameters = {}, method = 'get', form = null) => {
    try {
        method = method.toLowerCase();
        const response = await axios({
            url: route(routeName, method === 'get' ? parameters : null),
            method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            data: method === 'get' ? null : parameters
        });
        const value = await response.data;
        return value;
    } catch (error) {
        console.error(error);
        let message = error?.response?.data?.errors ?? null
        if (message) {
            onErrorService(message, form)
        }
        return null;
    }
}

// -----------------------------------------------------------------------------------------------------------

export const getStatus = async (category, symbol) => {
    try {
        const response = await axios.get(route('status-by-symbol', {
            category,
            symbol
        }), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        });
        const value = await response.data;
        return value;
    } catch (error) {
        console.error(error);
        return null;
    }
}

// -----------------------------------------------------------------------------------------------------------

export const getStatusId = async (category, symbol) => {
    const status = await getStatus(category, symbol);
    return status?.id;
}

// -----------------------------------------------------------------------------------------------------------

export const getStatusName = async (category, symbol) => {
    const status = await getStatus(category, symbol);
    return status?.status_name;
}

// -----------------------------------------------------------------------------------------------------------

export const getStatusSymbol = async (statusId) => {
    return await getField('statuses', 'symbol', { id: statusId });
}

// -----------------------------------------------------------------------------------------------------------

export const getAllStatuses = async (category) => {
    try {
        const response = await axios.get(route('status-get-all', {
            category
        }), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        });
        const value = await response.data;
        return value;
    } catch (error) {
        console.error(error);
        return null;
    }

}

// -----------------------------------------------------------------------------------------------------------

export const isStatusIn = async (statusId, category, arrSymbols) => {
    //if (!statuses) {
    let statuses = await getAllStatuses(category);
    //}
    console.log({ statusId, category, arrSymbols, statuses })
    return statuses.filter(status => arrSymbols.includes(status.symbol)).map(status => status.id).includes(statusId);
}

// -----------------------------------------------------------------------------------------------------------

export const isStatusNotIn = async (statusId, category, arrSymbols, statuses = null) => {
    return !await isStatusIn(statusId, category, arrSymbols, statuses);

}

// -----------------------------------------------------------------------------------------------------------

export const getExchange = async (fromValue, fromSymbol, toSymbol, date = null) => {
    try {
        const response = await axios.get(route('get-exchange', {
            fromValue,
            fromSymbol,
            toSymbol,
            date
        }), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        });
        const value = await response.data.toValue;
        return value;
    } catch (error) {
        console.error(error);
        return null;
    }
};

// -----------------------------------------------------------------------------------------------------------

export const convertDate = (dateToConvert) => {
    if (dateToConvert instanceof Date) {
        let year = dateToConvert.getFullYear();
        let month = dateToConvert.getMonth() + 1;
        let day = dateToConvert.getDate();

        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;

        dateToConvert = `${year}-${month}-${day}`;
    }
    return dateToConvert;
}

// -----------------------------------------------------------------------------------------------------------

export const currentDate = (separator = "-") => {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;

    return `${year}${separator}${month}${separator}${day}`;
}

// -----------------------------------------------------------------------------------------------------------

export const currentTime = (separator = ":") => {
    let date = new Date();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    if (hour < 10) hour = '0' + hour;
    if (minute < 10) minute = '0' + minute;
    if (second < 10) second = '0' + second;

    return `${hour}${separator}${minute}${separator}${second}`;

}

// -----------------------------------------------------------------------------------------------------------

export const currentYear = () => {
    return new Date().getFullYear();
}

// -----------------------------------------------------------------------------------------------------------

export const currentQuarter = (asSymbol = false) => {
    let quarter = Math.ceil(new Date().getMonth() / 3).toString().padStart(2, "0");
    if (asSymbol) {
        return '3' + quarter;
    }
    return quarter;
}

// -----------------------------------------------------------------------------------------------------------

export const currentMonth = (asSymbol = false) => {
    let month = (new Date().getMonth() + 1).toString().padStart(2, "0");
    if (asSymbol) {
        return '1' + month;
    }
    return month;
}

// -----------------------------------------------------------------------------------------------------------

export const currentDay = () => {
    return new Date().getDate();
}

// -----------------------------------------------------------------------------------------------------------

export const can = (value) => {
    let permissions = getStoreValue('permissions')
    var _return = false;

    if (!Array.isArray(permissions)) {
        return false;
    }

    value = value.toLowerCase().replace(/[_\s]/g, '-');
    permissions = permissions.map(permission => permission.toLowerCase().replace(/[_\s]/g, '-'));

    if (value.includes('|')) {
        value.split('|').forEach(function (item) {
            if (permissions.includes(item.trim())) {
                _return = true;
            }
        });
    } else if (value.includes('&')) {
        _return = true;
        value.split('&').forEach(function (item) {
            if (!permissions.includes(item.trim())) {
                _return = false;
            }
        });
    } else {
        _return = permissions.includes(value.trim());
    }
    return _return;
}

// -----------------------------------------------------------------------------------------------------------

export const is = (value) => {
    let roles = getStoreValue('roles')
    var _return = false;

    if (!Array.isArray(roles)) {
        return false;
    }

    value = value.toLowerCase().replace(/[_\s]/g, '-');
    roles = roles.map(role => role.toLowerCase().replace(/[_\s]/g, '-'));

    if (value.includes('|')) {
        value.split('|').forEach(function (item) {
            if (roles.includes(item.trim())) {
                _return = true;
            }
        });
    } else if (value.includes('&')) {
        _return = true;
        value.split('&').forEach(function (item) {
            if (!roles.includes(item.trim())) {
                _return = false;
            }
        });
    } else {
        _return = roles.includes(value.trim());
    }
    return _return;
}
// -----------------------------------------------------------------------------------------------------------

export const hasPermission = (arrPath) => {
    let path = null
    if (Array.isArray(arrPath) && arrPath.length > 0) {
        path = arrPath.join('.')
    } else {
        path = arrPath
    }

    let superAdminRole = getStoreValue('superAdminRole')
    return is(superAdminRole) || (path ? can(path) : false)
}

// -----------------------------------------------------------------------------------------------------------

export const hasRole = (arrPath) => {
    let path = null
    if (Array.isArray(arrPath) && arrPath.length > 0) {
        path = arrPath.join('.')
    } else {
        path = arrPath
    }

    return path ? is(path) : false
}

// -----------------------------------------------------------------------------------------------------------

export const checkRoute = (routeName, parameters = null, label = null) => {
    if (route().has(routeName)) {
        return route(routeName, parameters)
    } else {
        return route('route-not-exist', { 'label': label })
    }
}

// -----------------------------------------------------------------------------------------------------------

export const getConfigOptions = async (key = null) => {
    try {
        const response = await axios.get(route('thisapp-config'));
        const value = await response.data;
        if (key) {
            return value[key];
        } else {
            return value;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

// -----------------------------------------------------------------------------------------------------------

export const getCurrentDate = async () => {
    let currentDate = currentDate()
    try {
        currentDate = getStoreValue('currentDate')
        if (!currentDate) {
            const response = await axios.get(route('get-current-date'));
            const value = await response.data;
            currentDate = value?.currentDate ?? currentDate()
            setStoreValue('currentDate', currentDate)
        }
        return currentDate
    } catch (error) {
        console.error(error);
        return currentDate;
    }
}

// -----------------------------------------------------------------------------------------------------------

export const makeReport = async (reportParams, objConditions = {}, isLoading = null) => {
    if (isLoading) {
        isLoading.value = true;
    }

    for (let field in objConditions.value) {
        if (objConditions.value[field] instanceof Date) {
            objConditions.value[field] = convertDate(objConditions.value[field])
        }
    }

    const response = await getFromRoute("report-make", { ...reportParams, ...objConditions.value }, 'post');

    if (response?.message) {
        if (isLoading) {
            isLoading.value = false;
        }
        return screenMessage(response);
    }

    let attachment = "/" + decodeURIComponent(response.attachment)
    let fileName = attachment.split('/').pop()
    let a = document.createElement('a');

    a.href = attachment;
    a.download = fileName;
    document.body.append(a);

    a.click();
    a.remove();

    setTimeout(async () => {
        await getFromRoute("report-remove", { 'file_name': fileName }, 'get');
    }, 1000);

    if (isLoading) {
        isLoading.value = false;
    }
}

// -----------------------------------------------------------------------------------------------------------

export const printReport = (response, isLoading = null) => {
    if (isLoading) {
        isLoading.value = true;
    }

    let attachment = "/" + decodeURIComponent(response.attachment)
    let fileName = attachment.split('/').pop()
    let a = document.createElement('a');

    a.href = attachment;
    a.download = fileName;
    document.body.append(a);

    a.click();
    a.remove();

    setTimeout(async () => {
        await getFromRoute("report-remove", { 'file_name': fileName }, 'get');
    }, 1000);

    if (isLoading) {
        isLoading.value = false;
    }
}

// -----------------------------------------------------------------------------------------------------------

export const makeChart = (chartParams, objConditions = {}, isLoading = null) => {
    if (isLoading) {
        isLoading.value = true;
    }

    for (let field in objConditions.value) {
        if (objConditions.value[field] instanceof Date) {
            objConditions.value[field] = convertDate(objConditions.value[field])
        }
    }

    router.post(route('chart-make'), { ...chartParams, ...objConditions.value });

    if (isLoading) {
        isLoading.value = false;
    }
}

// -----------------------------------------------------------------------------------------------------------

export const numberFormat = (value, decimals = 2, decPoint = '.', thousandsSep = ' ') => {
    if (value === null || value === undefined) {
        return null;
    }

    return parseFloat(value)?.toFixed(decimals)?.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSep)?.replace('.', decPoint);
}

// -----------------------------------------------------------------------------------------------------------

export const isNumeric = (value) => {
    return !isNaN(value) && isFinite(value);
}

// -----------------------------------------------------------------------------------------------------------

export const isNullish = (value) => {
    return value === null || value === undefined || value.trim() === '';
}

// -----------------------------------------------------------------------------------------------------------

// export const loadComponent = async (componentPath) => {
//     const component = await import(/* @vite-ignore */'./' + componentPath + '.vue');
//     return component.default;
// };

// -----------------------------------------------------------------------------------------------------------

export function convertStringsToIntegers(obj) {
    if (Array.isArray(obj)) {
        return obj.map(item => convertStringsToIntegers(item));
    } else if (obj !== null && typeof obj === 'object') {
        for (const key in obj) {
            obj[key] = convertStringsToIntegers(obj[key]);
        }
        return obj;
    } else if (typeof obj === 'string' && !isNaN(obj)) {
        return parseInt(obj, 10);
    }
    return obj;
}

// -----------------------------------------------------------------------------------------------------------

export function translatePrimeVueMsgs() {
    const primevue = usePrimeVue();

    primevue.config.locale = {
        startsWith: __("Starts with"),
        contains: __("Contains"),
        notContains: __("Not contains"),
        endsWith: __("Ends with"),
        equals: __("Equals"),
        notEquals: __("Not equals"),
        noFilter: __("No Filter"),
        lt: __("Less than"),
        lte: __("Less than or equal to"),
        gt: __("Greater than"),
        gte: __("Greater than or equal to"),
        dateIs: __("Date is"),
        dateIsNot: __("Date is not"),
        dateBefore: __("Date is before"),
        dateAfter: __("Date is after"),
        clear: __("Clear"),
        apply: __("Apply"),
        matchAll: __("Match All"),
        matchAny: __("Match Any"),
        addRule: __("Add Rule"),
        removeRule: __("Remove Rule"),
        accept: __("Yes"),
        reject: __("No"),
        choose: __("Choose"),
        upload: __("Upload"),
        cancel: __("Cancel"),
        completed: __("Completed"),
        pending: __("Pending"),
        fileSizeTypes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        dayNames: [__('Sunday'), __('Monday'), __('Tuesday'), __('Wednesday'), __('Thursday'), __('Friday'), __('Saturday')],
        dayNamesShort: [__('Sun'), __('Mon'), __('Tue'), __('Wed'), __('Thu'), __('Fri'), __('Sat')],
        dayNamesMin: [__('Su'), __('Mo'), __('Tu'), __('We'), __('Th'), __('Fr'), __('Sa')],
        monthNames: [__('January'), __('February'), __('March'), __('April'), __('May'), __('June'), __('July'), __('August'), __('September'), __('October'), __('November'), __('December')],
        monthNamesShort: [__('Jan'), __('Feb'), __('Mar'), __('Apr'), __('May'), __('Jun'), __('Jul'), __('Aug'), __('Sep'), __('Oct'), __('Nov'), __('Dec')],
        chooseYear: __("Choose Year"),
        chooseMonth: __("Choose Month"),
        chooseDate: __("Choose Date"),
        prevDecade: __("Previous Decade"),
        nextDecade: __("Next Decade"),
        prevYear: __("Previous Year"),
        nextYear: __("Next Year"),
        prevMonth: __("Previous Month"),
        nextMonth: __("Next Month"),
        prevHour: __("Previous Hour"),
        nextHour: __("Next Hour"),
        prevMinute: __("Previous Minute"),
        prevSecond: __("Previous Second"),
        nextSecond: __("Next Second"),
        am: __("am"),
        pm: __("pm"),
        today: __("Today"),
        weekHeader: __("Wk"),
        firstDayOfWeek: 0,
        showMonthAfterYear: false,
        dateFormat: "rrrr/mm/dd",
        weak: __("Weak"),
        medium: __("Medium"),
        strong: __("Strong"),
        passwordPrompt: __("Enter a password"),
        emptyFilterMessage: __("No results found"),
        searchMessage: __("results are available"),
        selectionMessage: __("items selected"),
        emptySelectionMessage: __("No selected item"),
        emptySearchMessage: __("No results found"),
        emptyMessage: __("No available options"),
        noFilter: __("No filter"),
    };
}

// ----------------------------------------------------------------------------------------------------
