import { usePage } from '@inertiajs/vue3'
import axios from 'axios'
import { __, getSessionValue, setSessionValue, today, msToHours, setStoreValue } from "@helper/utils.js";
import { getAppName, setLocale } from "@helper/utils.js";

export const everyTimeCalledFunctions = () => {
    window.addEventListener("load", function () {
        functionsRunningAfterDomLoaded();
    });

    functionsRunningBeforeDomLoaded();
};

// ----------------------------------------------------------------------------------------------------

export const onlyOnceCalledFunctions = () => {
}

// ----------------------------------------------------------------------------------------------------

const functionsRunningAfterDomLoaded = () => { };

// ----------------------------------------------------------------------------------------------------

const functionsRunningBeforeDomLoaded = () => {
    serviceDisplaySessionTime();
};

// ----------------------------------------------------------------------------------------------------

const serviceDisplaySessionTime = () => {
    if (window.showDisplaySessionTime) {
        refreshSession();
    }
};

// ----------------------------------------------------------------------------------------------------

export const bindLogoutLink = () => {
    document.querySelector('#logout-link')?.addEventListener('click', function () {
        localStorage.clear()
        sessionStorage.clear()
    })
}

// ----------------------------------------------------------------------------------------------------

export const initFunctions = async () => {
    let response = null;
    try {
        const appName = getAppName()

        response = await axios(route("get-session"))
        if (response.status == 200) {
            const data = await response.data
            let appData = data;
            if (!appData) {
                appData = {};
            }
            sessionStorage.removeItem(appName);
            sessionStorage.setItem("appName", appName);
            sessionStorage.setItem(appName, JSON.stringify(appData));

            let localData = data["local_storage"];
            for (const key in localData) {
                localStorage.setItem(key, localData[key]);
            }
        }
        initialProcedures();

        response = await axios(route("get-views"))
        if (response.status == 200) {
            const viewsData = await response.data
            let objViews = JSON.parse(viewsData.views);

            for (const key in objViews) {
                sessionStorage.setItem(key, JSON.stringify(convertNumericValuesInObject(objViews[key])));
            }
        }

        const page = usePage();
        setStoreValue('permissions', page.props.permissions.permissions)
        setStoreValue('roles', page.props.permissions.roles)
        setStoreValue('superAdminRole', page.props.superAdminRole)
        setStoreValue('isCreator', page.props.isCreator)

    } catch (error) {
        console.error("Error during initFunctions in init.js file:", error);
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }
};

// ----------------------------------------------------------------------------------------------------

function initialProcedures() {
    loadTranslationsToLocal();
    displaySessionTime();
    bindLogoutLink()
}

// ----------------------------------------------------------------------------------------------------

const loadTranslationsToLocal = async () => {
    try {
        const response = await axios.get(route("get-translation"));
        if (response.status == 200) {
            const data = await response.data;
            window.objTran = data;

            let appName = getAppName()
            let appStorageData = sessionStorage.getItem(appName)
            let sessionData = JSON.parse(appStorageData);

            sessionData["translations"] = JSON.stringify(data);
            sessionStorage.setItem(appName, JSON.stringify(sessionData));
            setLocale();
        }
    } catch (error) {
        console.error("Error during loadTranslationsToLocal function in init.js file", error);
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }
};

// ----------------------------------------------------------------------------------------------------

const refreshSession = () => {
    if (!window.endOfSession) {
        window.lastActivityTime = today(true, true);
    }
};

// ----------------------------------------------------------------------------------------------------

const displaySessionTime = function () {
    let sessionTime = getSessionValue("evinronment.sessionTime");
    let lastActivityTimestamp = null;
    let diff, rest, msg, displayTime;
    let refreshLink =
        "<button><i class='fa-solid fa-arrows-rotate'></i></button>";

    if (sessionTime) {
        window.showDisplaySessionTime = true;
        window.lastActivityTime = today(true, true);

        msg = msToHours(sessionTime * 60 * 1000) + " " + refreshLink;
        document.querySelector(".session-time-header").innerHTML = msg;

        document
            .querySelector(".session-time-header")
            .addEventListener("click", function () {
                refreshSession();
            });

        window.displaySessionInterval = setInterval(() => {
            let currentTimestamp = new Date().getTime();
            let dateString =
                window.lastActivityTime?.substring(0, 10) +
                "T" +
                window.lastActivityTime?.substring(11, 20);
            lastActivityTimestamp = new Date(dateString).getTime();

            diff = currentTimestamp - lastActivityTimestamp;
            if (isNaN(diff)) {
                diff = 0;
            }

            rest = sessionTime * 60 * 1000 - diff + 1000;
            displayTime = msToHours(rest);

            if (rest > 0) {
                msg = displayTime + " " + refreshLink;
                let element = document.querySelector(".session-time-header");
                if (element) {
                    element.innerHTML = msg;
                }
            } else {
                msg = __("End of session");
                let element = document.querySelector(".session-time-header");
                if (element) {
                    element.innerHTML = msg;
                }
                window.endOfSession = true;
                window.lastActivityTime = null;

                clearInterval(window.displaySessionInterval);
                fetch(route("app-logout"), {}); //document.querySelector("#logout").click();
            }
        }, 1000 * 1);
    } else {
        window.showDisplaySessionTime = false;
    }
};

// ----------------------------------------------------------------------------------------------------

function convertNumericValuesInObject(obj) {
    const newObj = {};
    for (const prop in obj) {
        if (typeof obj[prop] === 'string' && !isNaN(obj[prop])) {
            newObj[prop] = Number(obj[prop]);
        } else {
            newObj[prop] = obj[prop];
        }
    }
    return newObj;
}

// ----------------------------------------------------------------------------------------------------
