<template>
    <v-form :name="props.searchProps.formName"
            @keyup.enter="autoSearch = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Search condition')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="last_name"
                                   label="Last name"
                                   size="3"
                                   :isFocus="true"
                                   v-model:field="search.last_name" />

                    <FormInputText fieldName="first_name"
                                   label="First name"
                                   size="3"
                                   v-model:field="search.first_name" />

                    <FormInputText fieldName="email"
                                   label="Email"
                                   size="3"
                                   v-model:field="search.email" />

                    <FormInputText fieldName="worker_sapid"
                                   label="SAP ID"
                                   size="3"
                                   v-model:field="search.worker_sapid" />

                </div>
                <div class="grid">
                    <FormMultiselect fieldName="manager_id"
                                     label="Manager"
                                     size="3"
                                     showClear
                                     :options="select?.managers_evaluators"
                                     :filter="(event) => onFilter(event, 'managers_evaluators')"
                                     v-model:field="search.manager_id" />

                    <FormMultiselect fieldName="evaluator1_id"
                                     label="Egzaminer 1"
                                     size="3"
                                     showClear
                                     :options="select?.managers_evaluators"
                                     :filter="(event) => onFilter(event, 'managers_evaluators')"
                                     v-model:field="search.evaluator1_id" />

                    <FormMultiselect fieldName="evaluator2_id"
                                     label="Egzaminer 2"
                                     size="3"
                                     showClear
                                     :options="select?.managers_evaluators"
                                     :filter="(event) => onFilter(event, 'managers_evaluators')"
                                     v-model:field="search.evaluator2_id" />

                    <FormMultiselect fieldName="evaluator3_id"
                                     label="Egzaminer 3"
                                     size="3"
                                     showClear
                                     :options="select?.managers_evaluators"
                                     :filter="(event) => onFilter(event, 'managers_evaluators')"
                                     v-model:field="search.evaluator3_id" />
                </div>
                <div class="grid">
                    <FormMultiselect fieldName="managers_evaluators"
                                     label="Any evaluator"
                                     size="3"
                                     showClear
                                     :options="select?.managers_evaluators"
                                     :filter="(event) => onFilter(event, 'managers_evaluators')"
                                     v-model:field="search.managers_evaluators" />

                    <FormMultiselect fieldName="worker_type"
                                     label="Worker type"
                                     size="2"
                                     showClear
                                     :options="select?.worker_types"
                                     v-model:field="search.worker_type" />
                    <FormMultiselect fieldName="dept_id"
                                     label="Department"
                                     size="3"
                                     showClear
                                     :options="select?.depts"
                                     :filter="(event) => onFilter(event, 'depts')"
                                     v-model:field="search.dept_id" />

                    <FormMultiselect fieldName="position_name"
                                     label="Position"
                                     size="4"
                                     showClear
                                     :options="select?.positions_by_name"
                                     :filter="(event) => onFilter(event, 'positions_by_name')"
                                     v-model:field="search.position_name" />
                </div>
                <div class="grid">
                    <FormDropdown fieldName="is_premium"
                                  label="Is premium system?"
                                  size="2"
                                  scrollHeight="100px"
                                  :showClear="true"
                                  :filtering="false"
                                  :options="yesNoOptions()"
                                  v-model:field="search.is_premium" />

                    <FormDropdown fieldName="is_working"
                                  label="Is working employee?"
                                  size="2"
                                  scrollHeight="100px"
                                  :showClear="true"
                                  :filtering="false"
                                  :options="yesNoOptions()"
                                  v-model:field="search.is_working" />

                    <FormMultiselect fieldName="premium_id"
                                     label="Premium group"
                                     size="3"
                                     showClear
                                     :filtering="false"
                                     :options="select?.premium_symbols"
                                     v-model:field="search.premium_id" />

                    <FormDropdown fieldName="worker_status"
                                  label="Worker status"
                                  size="5"
                                  :showClear="true"
                                  :filtering="false"
                                  :options="select?.worker_status"
                                  v-model:field="search.worker_status" />
                </div>
            </TabPanel>
        </TabView>


        <div class="grid">
            <SearchButtons :searchProps="searchProps"
                           :search="search"
                           v-model:autoSearch="autoSearch" />
        </div>
    </v-form>
</template>

<script setup>
import { ref } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import FormInputText from "@crud/FormInputText.vue";
import FormDropdown from "@crud/FormDropdown.vue";
import FormMultiselect from "@crud/FormMultiselect.vue";

import SearchButtons from '@crud/SearchButtons.vue'
import { prepareSearch, yesNoOptions, onFilterService } from '@pages/Helper/crud_utils.js'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    searchProps: Object,
})
const autoSearch = ref(false)

const isLoading = ref(true);
const options = ["positions_by_name", "depts", "worker_types", "premium_symbols", "worker_status", "managers_evaluators"];
const select = ref(null);

let search = ref({})

prepareSearch(search, props, options, select)

// -----------------------------------------------------

const onFilter = async (event, option) => {
    await onFilterService(event, option, select, isLoading, search, null);
}

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
