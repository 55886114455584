<template>
    <div>
        <Transition v-if="isLoading"
                    name="spinner">
            <div id="cover-spin"></div>
        </Transition>

        <ConfirmDialog header="Confirm operation"
                       question="Do you want to get organizational structure from SAP system?"
                       v-model="isConfirmationGetSapDeptsOpen"
                       :operation="actionGetSapDeptsButton" />

        <v-view :tableData="tableData"
                :tableProps="tableProps"
                :modalProps="modalProps"
                :columns="columns"
                :routes="routes"
                :initialSort="initialSort"
                :buttonsVisibility="buttonsVisibility"
                :rowButtonAction="rowButtonAction"
                v-model:selectedRows="selectedRows">
            <template #buttons="formProps">
                <div>
                    <Button @click.stop="openConfirmationGetSapDeptsWindow(formProps)"
                            style="background-color:lightsalmon;color:black;margin:0 7px 0 25px;white-space:nowrap"
                            severity="info">
                        <i class="fa-solid fa-users-viewfinder"
                           style="margin-right:5px;font-size:18px"></i>
                        {{ __('Get SAP organizational structure') }}
                    </Button>
                </div>
            </template>

            <template #crud="formProps">
                <DeptForm :formProps="formProps">
                </DeptForm>
            </template>
            <template #search="searchProps">
                <DeptSearch :searchProps="searchProps">
                </DeptSearch>
            </template>
        </v-view>
    </div>

</template>

<script setup>
import { ref } from "vue";
import DeptForm from './DeptForm.vue'
import DeptSearch from './DeptSearch.vue'
import ConfirmDialog from '@pages/Crud/ConfirmDialog.vue'
import Button from 'primevue/button'
import { rowButtonAction } from '@pages/Helper/crud_utils.js'
import { __, getFromRoute, screenMessage } from '@pages/Helper/utils.js'

const selectedRows = ref([])

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    initialSort: Object,
    buttonsVisibility: Object,
})

const isLoading = ref(false)
const isConfirmationGetSapDeptsOpen = ref(false)
const getLazyData = ref(false)

//------------------------------------------------------------------------------

const actionGetSapDeptsButton = async () => {
    isLoading.value = true
    const response = await getFromRoute('dept-get-from-sap', {}, 'get')
    isLoading.value = false

    if (getLazyData.value) {
        getLazyData.value()
    }

    if (response.status == 'success') {
        screenMessage({ message: response.message, title: "Information", type: "info", position: "center", timeout: 10 });
    } else {
        screenMessage({ message: response.message, title: "Information", type: "error", position: "center", timeout: 15 });
    }
}

//------------------------------------------------------------------------------

const openConfirmationGetSapDeptsWindow = (formProps) => {
    getLazyData.value = formProps.getLazyData
    isConfirmationGetSapDeptsOpen.value = true
}

//------------------------------------------------------------------------------

</script>

<style lang="scss" scoped></style>
