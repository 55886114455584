<template>
    <div>
        <Dialog modal
                :visible="isTestMailModalOpen"
                v-model:visible="isTestMailModalOpen"
                :header="__('Test mail')"
                :style="{ width: '500px' }">
            <p style="padding-bottom:15px;font-size:16px; font-weight:bold;color:cadetblue">{{ __('Enter email, subject and body text') }}</p>

            <TestMailForm v-model:isTestMailModalOpen="isTestMailModalOpen">
            </TestMailForm>
        </Dialog>

        <v-view :tableData="tableData"
                :tableProps="tableProps"
                :modalProps="modalProps"
                :columns="columns"
                :routes="routes"
                :initialSort="initialSort"
                :buttonsVisibility="buttonsVisibility"
                :rowButtonAction="rowButtonAction"
                v-model:selectedRows="selectedRows">
            <template #buttons="">
                <Button @click.stop="openTestMailWindow()"
                        style="background-color:darksalmon;color:black;margin:0 7px 0 0;white-space:nowrap"
                        severity="info">
                    <i class="fa-solid fa-sheet-plastic"
                       style="margin-right:5px;font-size:18px"></i>
                    {{ __('Test mail') }}
                </Button>
            </template>

            <template #crud="formProps">
                <MailingForm :formProps="formProps">
                </MailingForm>
            </template>
            <template #search="searchProps">
                <MailingSearch :searchProps="searchProps">
                </MailingSearch>
            </template>

        </v-view>
    </div>
</template>

<script setup>
import MailingForm from './MailingForm.vue'
import MailingSearch from './MailingSearch.vue'
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import TestMailForm from './TestMailForm.vue'
import { ref } from "vue";
import { rowButtonAction } from '@pages/Helper/crud_utils.js'
import { __ } from '@pages/Helper/utils.js'

const selectedRows = ref([])

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    initialSort: Object,
    buttonsVisibility: Object,
})

const isTestMailModalOpen = ref(false)

//------------------------------------------------------------------------------

const openTestMailWindow = () => {
    isTestMailModalOpen.value = true
}

//------------------------------------------------------------------------------

</script>

<style lang="scss" scoped></style>
