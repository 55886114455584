<template>
    <div>
        <v-view :tableData="tableData"
                :tableProps="tableProps"
                :modalProps="modalProps"
                :columns="columns"
                :routes="routes"
                :initialSort="initialSort"
                :renderColumnValue="renderColumnValue"
                :renderColumnAction="renderColumnAction"
                :rowButtonAction="rowButtonAction"
                :buttonsVisibility="buttonsVisibility">

            <template #crud="formProps">
                <OrgunitForm :formProps="formProps"> </OrgunitForm>
            </template>
            <template #search="searchProps">
                <OrgunitSearch :searchProps="searchProps"> </OrgunitSearch>
            </template>
        </v-view>
    </div>
</template>

<script setup>
import { rowButtonAction } from "@pages/Helper/crud_utils.js";
import OrgunitForm from "./OrgunitForm.vue";
import OrgunitSearch from "./OrgunitSearch.vue";
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    data: Object,
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    tooltipColumns: Object,
    initialSort: Object,
    buttonsVisibility: Object,
});

//------------------------------------------------------------------------------

const renderColumnValue = (field, value, data) => {
    switch (field) {
        case "is_premium":
            return value == 1 ? '<span class="flex justify-content-center"><i class="fa-solid fa-coins" style="font-size:20px;color:royalblue;"></i></span>' : "";
        case "is_working":
            //return value == 1 ? '<span class="flex justify-content-center"><i class="fa-solid fa-person-digging" style="font-size:20px;color:maroon;"></i></span>' : "";
            return value == 1 ? __('Yes') : __('No');
        default:
            return data.is_master_subordinate ? '<span style="color:blue">' + value + '</span>' : value;
    }
};

//------------------------------------------------------------------------------

const renderColumnAction = (item, rowData, column) => {
    let newItem = {}

    if (!rowData.is_premium) {
        newItem.tooltip = __('Not subject to premium system')
        newItem.prohibited = true
        newItem.name = '<i class="fa-solid fa-ban" style="color:black;"></i>'
    } else {
        newItem.prohibited = false
        newItem.tootip = item.tooltip
        newItem.name = item.name
    }

    return { ...newItem }
}

//------------------------------------------------------------------------------

</script>

<style lang="scss" scoped></style>
