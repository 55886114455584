<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form :name="props.formProps.formName">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Basic data')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="last_name"
                                   label="Last name"
                                   size="3"
                                   validation="required"
                                   :isFormDisabled="isFormDisabled"
                                   :isFocus="true"
                                   v-model:field="crud.last_name" />

                    <FormInputText fieldName="first_name"
                                   label="First name"
                                   size="3"
                                   validation="required"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.first_name" />

                    <FormInputText fieldName="email"
                                   label="Email"
                                   size="3"
                                   :validation="['required', 'email']"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.email" />

                    <FormInputText fieldName="worker_sapid"
                                   label="SAP ID"
                                   size="3"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.worker_sapid" />
                </div>
                <div class="grid">
                    <FormDropdown fieldName="manager_id"
                                  label="Manager"
                                  size="3"
                                  showClear
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.workers"
                                  :filter="(event) => onFilter(event, 'workers')"
                                  v-model:field="crud.manager_id" />

                    <FormDropdown fieldName="evaluator1_id"
                                  label="Egzaminer 1"
                                  size="3"
                                  showClear
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.workers"
                                  :filter="(event) => onFilter(event, 'workers')"
                                  v-model:field="crud.evaluator1_id" />

                    <FormDropdown fieldName="evaluator2_id"
                                  label="Egzaminer 2"
                                  size="3"
                                  showClear
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.workers"
                                  :filter="(event) => onFilter(event, 'workers')"
                                  v-model:field="crud.evaluator2_id" />

                    <FormDropdown fieldName="evaluator3_id"
                                  label="Egzaminer 3"
                                  size="3"
                                  showClear
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.workers"
                                  :filter="(event) => onFilter(event, 'workers')"
                                  v-model:field="crud.evaluator3_id" />
                </div>
                <div class="grid">
                    <FormInputText fieldName="pesel"
                                   label="PESEL"
                                   size="3"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.pesel" />

                    <FormInputText fieldName="classify_group"
                                   label="Classify group"
                                   size="3"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.classify_group" />

                    <FormDropdown fieldName="worker_type"
                                  label="Position type"
                                  size="3"
                                  showClear
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.worker_types"
                                  v-model:field="crud.worker_type" />

                    <FormDropdown fieldName="premium_group_id"
                                  label="Premium group"
                                  size="3"
                                  :filtering="false"
                                  :isFormDisabled="true"
                                  :options="select?.premium_symbols"
                                  v-model:field="premium_group_id" />
                </div>
                <div class="grid">
                    <FormDropdown fieldName="dept_id"
                                  label="Department"
                                  size="6"
                                  showClear
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.depts"
                                  :filter="(event) => onFilter(event, 'depts')"
                                  v-model:field="crud.dept_id" />

                    <FormDropdown fieldName="position_id"
                                  label="Position"
                                  size="6"
                                  showClear
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.positions"
                                  :filter="(event) => onFilter(event, 'positions')"
                                  v-model:field="crud.position_id" />
                </div>
                <div class="grid">
                    <FormInputToggleButton fieldName="is_premium"
                                           label="Is premium system?"
                                           size="3"
                                           :isFormDisabled="isFormDisabled"
                                           v-model:field="crud.is_premium" />

                    <FormInputToggleButton fieldName="is_working"
                                           label="Is working employee?"
                                           size="3"
                                           :isFormDisabled="isFormDisabled"
                                           v-model:field="crud.is_working" />
                </div>
                <div class="grid">
                    <FormCalendar fieldName="premium_from_date"
                                  label="Under premium system from"
                                  size="2"
                                  :isFormDisabled="isFormDisabled"
                                  v-model:field="crud.premium_from_date" />
                    <FormCalendar fieldName="premium_to_date"
                                  label="Under premium system to"
                                  size="2"
                                  :isFormDisabled="isFormDisabled"
                                  v-model:field="crud.premium_to_date" />
                    <FormCalendar fieldName="dismissal_date"
                                  label="Dismissal date"
                                  size="2"
                                  :isFormDisabled="isFormDisabled"
                                  v-model:field="crud.dismissal_date" />

                </div>
                <div class="grid">
                    <FormEditor fieldName="description"
                                label="Description"
                                :editorStyle="{ height: '120px' }"
                                :isFormDisabled="isFormDisabled"
                                v-model:field="crud.description" />
                </div>
            </TabPanel>

            <TabPanel v-if="crud.log"
                      :header="__('Changelog')"
                      headerClass="form-tab-header">
                <ChangeLog :log="crud?.log" />
            </TabPanel>
        </TabView>

        <div class="grid">
            <FormButtons :formProps="props.formProps"
                         :sourceForm="crud"
                         :validation="validation"
                         :buttonsVisibility="buttonsVisibility"
                         v-model:isFormDisabled="isFormDisabled"> </FormButtons>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted, watchEffect } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { validation, initForm } from "@pages/Helper/crud_utils.js";
import FormInputText from "@crud/FormInputText.vue";
import FormDropdown from "@crud/FormDropdown.vue";
import FormButtons from "@crud/FormButtons.vue";
import FormEditor from "@crud/FormEditor.vue";
import FormCalendar from "@crud/FormCalendar.vue";
import FormInputToggleButton from "@crud/FormInputToggleButton.vue";
import ChangeLog from "@crud/ChangeLog.vue";
import { __, getField } from "@pages/Helper/utils.js";
import { onFilterService } from "@pages/Helper/crud_utils.js";

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
});

const isLoading = ref(true);
const isFormDisabled = ref(false);

const options = ["workers", "positions", "depts", "worker_types", "premium_symbols"];
const select = ref(null);
const buttonsVisibility = ref({});
const crud = ref({});

const premium_group_id = ref(null);

// -----------------------------------------------------

watchEffect(async () => {
    if (crud.value.position_id) {
        premium_group_id.value = await getField("positions", "premium_id", "positions.id=" + crud.value.position_id);
    }
})

// -----------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, options, select, buttonsVisibility);
});

// -----------------------------------------------------

const onFilter = async (event, option) => {
    await onFilterService(event, option, select, isLoading, null, null);
}

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
