<template>
    <Dialog modal
            :visible="true"
            :closable="false"
            :header="__('Collective sheets update')"
            :style="{ width: 'auto' }">

        <v-form name="collectiveSheetForm"
                class=""
                style="padding:10px;">
            <div v-if="collectiveData.length == 0"
                 class="grid sheet-total-result justify-content-center"
                 style="margin-bottom:20px">
                {{ __('No workers to assess') }}
            </div>
            <div v-for="(premiumGroupWorkers, premium_group_symbol) in workerSheetsData"
                 :key="premium_group_symbol">

                <div class="grid sheet-section-title justify-content-center"
                     style="padding-bottom:15px;">
                    {{ __('Premium group') }}: {{ premium_group_symbol }}
                </div>

                <div v-for="(row, worker_data) in premiumGroupWorkers"
                     :key="worker_data"
                     class="grid">
                    <div class="col-12 worker-name sheet-box-divider"
                         style="margin-bottom:0;margin-left:8px;padding-left:5px">{{ worker_data }}</div>
                    <div class="col-12">
                        <FormRepeaterTable v-model:record="collectiveData[premium_group_symbol][worker_data]"
                                           :keys="{ 'criterion_name': null, 'weight': 0, 'assessment': 0, 'result': null, 'justification': null }"
                                           :disabled="false"
                                           :isFirstRowEmpty="false"
                                           :isCopyButton="false"
                                           :isDeleteButton="false"
                                           :isAddButton="false"
                                           cellStyle="vertical-align:middle;"
                                           cellClass="sheet-cells"
                                           repeaterStyle=""
                                           name="sheetCriterions"
                                           :title="[__('Criterion name'), __('Weight'), __('Assessment'), __('Result'), __('Justification')]">
                            <template #repeat="slotProps">
                                <td :class="slotProps.cellClass"
                                    :style="slotProps.cellStyle">
                                    <FormDropdown :style="{ width: 'auto' }"
                                                  fieldName="weight"
                                                  optionClass="sheet-option-items"
                                                  inputStyle="width:10px"
                                                  class="weight-criterion"
                                                  :itemSize="20"
                                                  :isFormDisabled="true"
                                                  :inRepeater="true"
                                                  :filtering="false"
                                                  :options="select?.criterion_names"
                                                  v-model:field="slotProps.record.criterion_name" />
                                </td>

                                <td :class="slotProps.cellClass"
                                    :style="slotProps.cellStyle">
                                    <FormDropdown :style="{ width: 'auto' }"
                                                  fieldName="weight"
                                                  optionClass="sheet-option-items"
                                                  inputStyle="width:10px"
                                                  class="weight-criterion"
                                                  :itemSize="20"
                                                  :isFormDisabled="slotProps.record.is_weights_change == 0"
                                                  :inRepeater="true"
                                                  :filtering="false"
                                                  :options="select?.weights"
                                                  :change="() => countCriterionResults(slotProps.record)"
                                                  v-model:field="slotProps.record.weight" />
                                </td>
                                <td :class="slotProps.cellClass"
                                    :style="slotProps.cellStyle">
                                    <FormDropdown :style="{ width: 'auto' }"
                                                  fieldName="assessment"
                                                  optionClass="sheet-option-items"
                                                  inputStyle="width:50px;"
                                                  scrollHeight="120px"
                                                  :itemSize="25"
                                                  :isFormDisabled="false"
                                                  :inRepeater="true"
                                                  :filtering="false"
                                                  :options="select?.assessments"
                                                  :change="() => countCriterionResults(slotProps.record)"
                                                  v-model:field="slotProps.record.assessment" />
                                </td>
                                <td :class="slotProps.cellClass"
                                    :style="slotProps.cellStyle"
                                    style="width:10px;text-align:center;">
                                    <span class="static-text-label">
                                        {{ Math.round(slotProps.record.weight * slotProps.record.assessment / 100) }}%
                                    </span>
                                </td>

                                <td :class="slotProps.cellClass"
                                    :style="slotProps.cellStyle">
                                    <FormTextarea fieldName="justification"
                                                  inputClass="sheet-fields"
                                                  style="padding-right:30px"
                                                  :rows="2"
                                                  :cols="30"
                                                  :isFormDisabled="false"
                                                  :inRepeater="true"
                                                  v-model:field="slotProps.record.justification" />
                                </td>
                            </template>
                        </FormRepeaterTable>
                    </div>

                </div>
            </div>

            <div class="grid">
                <Button v-if="!isObjectEmpty(collectiveData)"
                        @click="saveAsReady"
                        :disabled="collectiveData?.processing"
                        style="margin:0 5px"
                        severity="success">
                    {{ __('Save as ready') }}
                </Button>
                <Button v-if="!isObjectEmpty(collectiveData)"
                        @click="saveAssessment"
                        :disabled="collectiveData?.processing"
                        style="margin:0 5px"
                        severity="info">
                    {{ __('Save assessment') }}
                </Button>

                <Button @click="cancelData"
                        :disabled="collectiveData?.processing"
                        style="margin:0 5px"
                        severity="secondary">
                    {{ __('Cancel') }}
                </Button>
            </div>
        </v-form>
        <!-- </div> -->
    </Dialog>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useForm, router } from '@inertiajs/vue3'
import Button from 'primevue/button';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormDropdown from '@crud/FormDropdown.vue'
import FormRepeaterTable from '@crud/FormRepeaterTable.vue'
import FormTextarea from '@crud/FormTextarea.vue'
import Dialog from 'primevue/dialog';
import FormInputNumber from '@crud/FormInputNumber.vue'
import { validation, prepareSelect } from '@pages/Helper/crud_utils.js'
import { __, screenMessage, getFromRoute, isObjectEmpty } from '@pages/Helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    workerSheetsData: Object
})

const collectiveData = ref(props.workerSheetsData)
const criterionResults = ref(null)

// -----------------------------------------------------

const isLoading = ref(true);
let select = ref(null)

// -----------------------------------------------------

onMounted(async () => {
    isLoading.value = true;
    select.value = await prepareSelect(['criterion_names', 'weights', 'assessments']);
    isLoading.value = false;
})

// -----------------------------------------------------

const countCriterionResults = (record) => {
    //console.log(record)
    criterionResults.value = { [record.worker_id]: { [record.premiumcriterion_id]: record.weight * record.assessment } }
}

// -----------------------------------------------------

const cancelData = () => {
    router.get(route('worker-index'));
}

// -----------------------------------------------------

const saveAsReady = () => {
    saveData('saveAsReady');

}
// -----------------------------------------------------

const saveAssessment = () => {
    saveData('saveAssessment');

}
// -----------------------------------------------------

const saveData = async (saveMethod) => {
    const resultCheck = await getFromRoute('sheet-collective-check', { collectiveData: collectiveData.value, saveMethod }, 'post')
    if (resultCheck.status === 'error') {
        const message = {
            message: resultCheck.message,
            title: "Collective sheet filling error",
            type: "error",
            position: "center",
            timeout: 5,
        };
        screenMessage(message);
        return
    }

    const resultSave = await getFromRoute('sheet-collective-save', { collectiveData: collectiveData.value, saveMethod }, 'post')
    if (resultSave.status === 'error') {
        const message = {
            message: resultSave.message,
            title: "Collective sheet saving error",
            type: "error",
            position: "center",
            timeout: 5,
        };
        screenMessage(message);
        return
    }

    const message = {
        message: resultSave.message,
        title: "Information",
        type: "success",
        position: "center",
        timeout: 5,
    };
    screenMessage(message);

    router.get(route('worker-index'));
}
// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
