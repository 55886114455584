<template>
    <div>
        <Transition v-if="isLoading"
                    name="spinner">
            <div id="cover-spin"></div>
        </Transition>
        <v-form name="sandboxForm">
            <ConfirmDialog header="Confirm operation"
                           question="Do you want to run sandbox code?"
                           v-model="isConfirmationOpen"
                           :operation="runSandboxCode" />

            <div class="grid">
                <Button @click.stop="openConfirmationWindow()"
                        style="background-color:cadetblue;margin:0 7px 0 25px"
                        severity="info">
                    <i class="fa-solid fa-unlock-keyhole"
                       style="margin-right:5px;font-size:18px"></i>{{ __('Uruchom kod Sandbox') }}</Button>

            </div>
        </v-form>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import ConfirmDialog from '@pages/Crud/ConfirmDialog.vue'
import Button from 'primevue/button'
import { __, getFromRoute, screenMessage } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})

const isConfirmationOpen = ref(false)
const isLoading = ref(false);

// -----------------------------------------------------

onMounted(async () => {

})

// -----------------------------------------------------

const openConfirmationWindow = () => {
    isConfirmationOpen.value = true
}

// -----------------------------------------------------

const runSandboxCode = async () => {
    try {
        isLoading.value = true
        const returnData = await getFromRoute('sandbox-run', {})
        isLoading.value = false

        console.log(returnData)
    } catch (e) {
        console.err(e)
    }

    screenMessage({ message: __('Sandbox code was executed'), title: "Information", type: "info", position: "center", timeout: 5 });
}

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
