<template>
    <form :name="name"
          @submit.prevent>
        <slot></slot>
    </form>
</template>

<script setup>
import { watch, onMounted } from 'vue'

const props = defineProps({
    isFormDisabled: { type: Boolean, default: false },
    autoSave: { type: Boolean, default: false },
    autoSearch: { type: Boolean, default: false },
    name: { type: String, default: 'not-provided-form-name' }
})

//--------------------------------------------------------------------------------------------------------

onMounted(() => {
    const footerAdminArea = document.querySelector('.admin-area-in-footer')
    if (footerAdminArea) {
        footerAdminArea.textContent = props?.name ?? ''
    }
})

//--------------------------------------------------------------------------------------------------------

watch(() => props.isFormDisabled, (newValue, oldValue) => {
    document.querySelectorAll('form[name="' + props.name + '"] [name]').forEach(element => {
        // element.disabled = newValue;
        // console.log(element.tagName, element)
        // if (element.tagName !== 'INPUT' && element.tagName !== 'TEXTAREA') {
        // 	if (newValue) {
        // 		console.log('nowa wartość')
        // 		element.classList.add('disabled-div-span')
        // 	} else {
        // 		element.classList.add('disabled-div-span')
        // 	}
        // }

        let labelBeforeElement = element.previousElementSibling;
        while (labelBeforeElement) {
            if (labelBeforeElement.tagName === 'LABEL') {
                break;
            }
            labelBeforeElement = labelBeforeElement.previousElementSibling;
        }

        if (newValue) {
            labelBeforeElement?.classList.add('disabled-label')
        } else {
            labelBeforeElement?.classList.remove('disabled-label')
        }
    });
}, { deep: true, immediate: true })

//--------------------------------------------------------------------------------------------------------

</script>

<style lang="scss" scoped></style>
